import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Usuario } from '../shared/models/usuario.model';
import { VariablesService } from './variableGL.service';
import { AuthService } from './auth.service';
import { URL_ServAccount } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    users = new BehaviorSubject<Usuario[]>(null);
    user = new BehaviorSubject<Usuario>(null);
    status = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClientService,
        private router: Router,
        private variablesGL: VariablesService,
        private auth: AuthService
    ) { }

    getUsers() {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServAccount}/users`)
            .subscribe((res: any) => {
                if (res) {
                    this.users.next(res);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    getUser(id: number) {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServAccount}/user?id=${id}`)
            .subscribe((res: any) => {
                if (res) {
                    this.user.next(new Usuario(res.id, res.nombre, res.apellidos, res.correo, res.telefono, res.habilitado, res.rol.id, res.rol.nombre, null, null, res.avatar, res.arbol));
                    this.router.navigate([`/usuario/${res.nombre}`]);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    postUser(data: any) {
        this.http.post(`${URL_ServAccount}/newuser`, { data: data })
            .subscribe((res: any) => {
                if (res)
                    this.router.navigate(['/usuarios'], { replaceUrl: true });
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    putUser(data: any) {
        this.http.post(`${URL_ServAccount}/updateuser`, { data: data })
            .subscribe((res: any) => {
                if (res)
                    this.router.navigate(['/usuarios'], { replaceUrl: true });
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    deleteUser(id: number) {
        this.http.get(`${URL_ServAccount}/deleteuser?id=${id}`)
            .subscribe((res: any) => {
                if (res)
                    this.router.navigate(['/usuarios'], { replaceUrl: true });
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    putUserStatus(data: any) {
        this.http.post(`${URL_ServAccount}/activeuser`, { data: data })
            .subscribe((res: any) => {
                if (res)
                    this.status.next(res)
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    putUserAvatar(avatar) {
        this.http.post(`${URL_ServAccount}/avataruser`, avatar)
            .subscribe((res: any) => {
                if (res) {
                    this.auth.user.value.avatar = `/assets/avatar/${avatar}.svg`;
                    this.auth.user.next(this.auth.user.value);
                }
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }
}
