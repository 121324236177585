import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'phone' })

export class PhonePipe implements PipeTransform {
    transform(val, args): any {
        val = val.charAt(0) != 0 ? val : '' + val;
        let newStr = '';
        let i;

        for (i = 0; i < (Math.floor(val.length / 2) - 1); i++) {
            newStr = newStr + val.substr(i * 2, 2) + '-';
        }
        return newStr + val.substr(i * 2);

        //    let  newVal = val.substring(0,3)+'-'+val.substring(3,6)+'-'+val.substring(6,8)+'-'+val.substring(8,val.length);
        //     return newVal;
    }
}