import { Component, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';

@Component({
  selector: 'app-side-messages',
  templateUrl: './side-messages.component.html',
  styleUrls: ['./side-messages.component.css']
})
export class SideMessagesComponent implements OnInit {

  messages: any = null;
  colores = null;

  constructor(
    private servVariables: VariablesService
  ) { 
    this.colores = servVariables.colores;
  }

  ngOnInit() {
    this.messages = [
      { label: "IH", envio:"Irwing Herrera", color: this.colores[0], message: "Hola que tal.", date: "9/4/2019 12:15" },
      { label: "AH", envio:"Allison Herrera", color: this.colores[1], message: "Me gustaria que realizaras.", date: "9/4/2019 12:15" },
      { label: "AT", envio:"Alexis Tamariz", color: this.colores[2], message: "Hola como te va amigo.", date: "9/4/2019 12:15" },
      { label: "JG", envio:"Jonothan Gonzalez", color: this.colores[3], message: "Oye podrias apoyarme.", date: "9/4/2019 12:15" },
    ];
  }

}
