import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Notificacion } from 'src/app/shared/models/notificacion';
import { WsNtfrService } from './ws-ntfr.service';

@Injectable({
  providedIn: 'root'
})

export class NtfrService {
  public messages: Subject<Notificacion>;

  constructor(private wsNtfr: WsNtfrService) {}

  connect(url) {
    this.messages = <Subject<Notificacion>>this.wsNtfr
      .connect(url)
      .pipe(map((response: MessageEvent): Notificacion => {
        return JSON.parse(response.data);
      }));
  }

  onMessage(call) {
    this.messages.subscribe(msg => {
      if (call) call(<Notificacion>msg);
    });
  }
}