import { Component, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';
import { MenuItem, TreeNode } from 'primeng/api';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { BehaviorSubject } from 'rxjs';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  activeMenuChico: boolean;
  pasos: MenuItem[];
  activestep = new BehaviorSubject<number>(0);
  pos = 0;
  // Seccion Rol
  roles: any[];
  selectedRol: any;
  notSelectedRol: boolean;

  arbol: TreeNode[];
  saveArbol: string = "";
  usuario: Usuario;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.activestep.subscribe(n => {
      if (this.activestep.value == 1) {
        this.pos = 1;
        this.roles = [
          { name: 'Administrador', id: '1' },
          { name: 'Jefe', id: '2' },
          { name: 'Encargado de Zona', id: '3' },
          { name: 'Proveedor', id: '4' }
        ];
      }
    });
    this.pasos = [
      { label: 'Datos Personales' },
      { label: 'Seleccion de Rol' },
      { label: 'Finalización' }
    ];
  }

  changeRol() {
    if (this.selectedRol) {
      this.notSelectedRol = true;
      switch (this.selectedRol.name) {
        case "Administrador":
          this.arbol = [
            { "label": "Gráficas" },
            { "label": "Mapa" },
            { "label": "Notificaciones" },
            { "label": "Usuarios" },
            { "label": "Zonas" },
            { "label": "Reportes" },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        case "Jefe":
          this.arbol = [
            { "label": "Gráficas" },
            { "label": "Mapa" },
            { "label": "Usuarios" },
            { "label": "Zonas" },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        case "Encargado de Zona":
          this.arbol = [
            { "label": "Mapa" },
            {
              "label": "Proveedor", "children": [
                { "label": "INCOTEX" },
                { "label": "MARFEL" }
              ]
            },
            {
              "label": "Zonas", "children": [
                { "label": "Zona 1" },
                { "label": "Zona 2" },
                { "label": "Zona 3" },
                { "label": "Zona 4" },
                { "label": "Zona 5" },
                { "label": "Zona 6" },
                { "label": "Zona 7" },
                { "label": "Zona 8" },
                { "label": "Zona 9" },
                { "label": "Zona 10" },
                { "label": "Zona 11" },
                { "label": "Zona 12" },
                { "label": "Zona 13" }
              ]
            },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        case "Proveedor":
          this.arbol = [
            { "label": "Mapa" },
            {
              "label": "Zonas", "children": [
                { "label": "Zona 1" },
                { "label": "Zona 2" },
                { "label": "Zona 3" },
                { "label": "Zona 4" },
                { "label": "Zona 5" },
                { "label": "Zona 6" },
                { "label": "Zona 7" },
                { "label": "Zona 8" },
                { "label": "Zona 9" },
                { "label": "Zona 10" },
                { "label": "Zona 11" },
                { "label": "Zona 12" },
                { "label": "Zona 13" }
              ]
            },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        default:
          break;
      }
    }
  }

  nodeSelect(event) {
    this.findNodo(event.node, true);
  }

  findNodo(node: any, checked: boolean) {
    try {
      if (node.children && node.children.length > 0) {
        node.partialSelected = checked;
        for (let i = 0; i < node.children.length; i++) {
          node.children[i].partialSelected = checked;
          this.findNodo(node.children[i], checked);
        }
      }
      else {
        node.partialSelected = checked;
      }
    } catch (e) {
      console.error();
    }
  }

  passForm2(dataUser) {
    this.usuario = new Usuario();
    this.usuario.nombre = dataUser.nombre;
    this.usuario.apellidos = dataUser.apellidos;
    this.usuario.correo = dataUser.correo;
    this.usuario.telefono = dataUser.telefono;

    this.activestep.next(1);
  }

  customTree() {
    if (this.selectedRol) {
      let _arbol: any[] = [];
      this.arbol.map(nodo => {
        if (nodo.partialSelected && nodo.partialSelected == true && nodo.children && nodo.children.length > 0) {
          let _hijos: any = [];
          _arbol.push({ pantalla: nodo.label });
          for (let i = 0; i < nodo.children.length; i++) {
            if (nodo.children[i].partialSelected && nodo.children[i].partialSelected == true)
              _hijos.push(nodo.children[i].label);
          }
          let _index = _arbol.findIndex((f) => { return (f.pantalla == nodo.label) });
          _arbol[_index] = { pantalla: nodo.label, hijos: _hijos }
        }
        else {
          if (nodo.partialSelected && nodo.partialSelected == true)
            _arbol.push({ pantalla: nodo.label });
        }
      });

      this.saveArbol = JSON.stringify(_arbol);
      this.pos = 2;
    } else {
      this.notSelectedRol = false;
    }
  }

  Crear() {
    if (this.pos == 2) {

      this.usuario.telefono = this.usuario.telefono.replace(/[\[\]-]+/g, '');

      let newUsuario = {
        usuario: JSON.stringify(this.usuario),
        rol: this.selectedRol.name,
        navegacion: this.saveArbol
      };

      this.usuarioService.postUser(newUsuario);
    }
  }

  Cancelar() {
    this.router.navigate(['/usuarios']);
  }
}
