import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NtfrService } from './ntfr.service';
import { MetodosSocket } from './metodosSocket.service';
import { AuthService } from '../auth.service';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { VariablesService } from '../variableGL.service';

@Injectable({
    providedIn: 'root'
})

export class InicioSocketService {

    usuario: Usuario;

    constructor(
        private ntfr: NtfrService,
        private metodosSocket: MetodosSocket,
        private authService: AuthService,
        private variablesGL: VariablesService,
    ) {
        this.authService.user.subscribe(r => {
            if (r)
                this.usuario = r;
        });
    }

    init() {
        let connection = setInterval(() => {
            this.ntfr.connect(`${environment.URL_Socket}`);
            this.waiter(connection);
        }, 2000);
    }

    waiter(connection) {
        this.ntfr.onMessage(msg => {
            clearInterval(connection);
            console.log(msg);

            if (msg.tipo) {
                switch (msg.tipo) {
                    case 1: //Recargar Cards, Barras, Barras por Zona
                        let sonidoActual = this.variablesGL.SonidoAlarmaSW.value;
                        if(sonidoActual){
                            setTimeout(() => {
                                this.variablesGL.SonidoAlarmaSW.next(false);
                            }, 4000);
                        }
                        this.metodosSocket.updateDashboard_1(msg.mensaje);
                        break;
                    case 2: //Recargar Envios y Gauges
                        this.metodosSocket.updateDashboard_2(msg.mensaje);
                        break;
                    case 3: //Agregar Notificación
                        if (this.usuario.rol == "Administrador") {
                            this.metodosSocket.addAlerta(msg.mensaje);
                        }
                        break;
                    default:
                        clearInterval(connection);
                        break;
                }
            }

        });
    }
}