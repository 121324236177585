import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from '../../shared/animations/fade';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { alertMessage } from 'src/app/shared/models/alertMessage.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [fadeAnimation],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  pagina = 1;
  recuperarCorreo;
  alert: alertMessage;


  constructor(
    private AuthService: AuthService,
    private router: Router,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.alert = new alertMessage(
      null,
      false,
      null,
      null,
      null,
    );
  }

  activar(page) {
    this.pagina = page;
  }

  getDataLogin(e) {
    if (e) {
      let user = {
        correo: e.correo,
        contrasena: e.contrasena
      }

      this.AuthService.login(user, (allowed) => {
        if (!allowed)
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acceso denegado', detail: 'Credenciales Incorrectas', life: 1000, closable: false });
        else {
          this.router.navigate(['/'], { replaceUrl: true });
          return Swal.fire("Bienvenido", `${e.correo}`, "success");
        }
      });
    }
  }
  getDataUser(e) {
    if (e) {
      let user = {
        correo: e.correo,
      }
      this.AuthService.forgotUser(user).subscribe(datauser => {
        if (datauser) {
          const datamailing = datauser;
          this.AuthService.resetPasswordMailing(datamailing).subscribe((datamail: any) => {
            if (datamail.success === true && datamail.statussend === true) {
              this.alert.alertClass = 'success';
              this.alert.alertShow = true;
              this.alert.alertHeader = 'Se ha enviado un correo para recuperar tu contraseña.'
              this.alert.alertMessage = null;
              this.alert.alertDetails = 'Si no aparece en la bandeja de entrada consulte la carpeta de spam';
            } else {
              this.alert.alertClass = 'danger';
              this.alert.alertShow = true;
              this.alert.alertHeader = 'No se encontro correo asociado al usuario.';
              this.alert.alertMessage = null;
              this.alert.alertDetails = 'Verifique que su información este actualizada';
            }
          }, error => console.log("Error:", error));
        }
      }, error => {
        this.alert.alertClass = 'danger';
        this.alert.alertShow = true;
        this.alert.alertHeader = 'No se encontro el usuario.';
        this.alert.alertMessage = null;
        this.alert.alertDetails = 'Verifique que su información este actualizada';
      });
    }
    setTimeout(() => {
      this.alert.alertShow = false;
    }, 2500);
  }
}
