import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { VariablesService } from './variableGL.service';
import { Zona } from '../shared/models/zona.model.1';
import { ZonaTemporal } from '../shared/models/zonaTemp.model';
import { URL_ServZonas } from '../config/config';
import { HttpClientService } from './implementation/http-client.service';

@Injectable({
    providedIn: 'root'
})
export class ZonasService {

    zonas = new BehaviorSubject<any>(null);
    zonaNueva = new BehaviorSubject<Zona>(null);
    zonaEliminada = new BehaviorSubject<number>(null);

    constructor(
        private http: HttpClientService,
        private router: Router,
        private variablesGL: VariablesService,
    ) { }

    getZonasCreadas() {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServZonas}/zonecreate`)
            .subscribe((res: any) => {
                if (res) {
                    if (res.mensaje != "Nuevas Zonas") {
                        res.listZonas = []
                    }
                    this.zonas.next(res);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    postZona(zona: Zona, call) {
        this.http.post(`${URL_ServZonas}/newzone`, zona)
            .subscribe((res: any) => {
                if (res) {
                    this.zonaNueva.next(res);
                }
                call(res ? true : false)
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    deleteZona(idZona: number) {
        this.http.get(`${URL_ServZonas}/deletezone?id=${idZona}`)
            .subscribe((res: any) => {
                if (res) {
                    this.zonaEliminada.next(idZona);
                }
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    putZona(data: ZonaTemporal, call) {
        this.http.post(`${URL_ServZonas}/zone`, { data: data })
            .subscribe((res: any) => {
                call(res ? true : false)
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }
}
