import { Component, OnChanges } from '@angular/core';
import { Alerta } from '../../models/alerta.model';
import { MetodosSocket } from 'src/app/services/WebSocket/metodosSocket.service';
import { DashBoardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-side-alertas',
  templateUrl: './side-alertas.component.html',
  styleUrls: ['./side-alertas.component.css']
})
export class SideAlertasComponent implements OnChanges {
  
  alertas: Alerta[] = [];

  constructor(
    private metodosSocket: MetodosSocket,
    private dashbaordService:DashBoardService
  ) { 
    this.dashbaordService.alertas.subscribe(r => {
      if (r)
        this.alertas = r;
    });
    
    this.metodosSocket.alerta.subscribe(r => {
      if (r) {
        if(this.alertas.length > 0) {
          if (!this.alertas.find(x => x.id == r.id)) { 
            this.alertas.unshift(r);
            if (this.alertas.length > 4) {
              this.alertas.pop();
            }
            this.metodosSocket.showAlertas(r);
          }
        }
        else {
          this.alertas.unshift(r);
          this.metodosSocket.showAlertas(r);
        }
      }
    });
  }

  ngOnChanges() {}

}
