import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ZonaTemporal } from 'src/app/shared/models/zonaTemp.model';

@Component({
  selector: 'app-fadd-zona',
  templateUrl: './fadd-zona.component.html',
  styleUrls: ['./fadd-zona.component.css']
})
export class FaddZonaComponent implements OnChanges {

  @Input() zona: string;
  zonaTemp: ZonaTemporal;

  @Output() dataZona = new EventEmitter<any>();

  form: FormGroup;
  submitted = false;
  spiner: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnChanges() {
    if (this.zona != "")
      this.form = this.formBuilder.group({
        nombre: [this.zona, [Validators.required, Validators.minLength(4), Validators.maxLength(10)]],
      });
    else if (this.zona == "")
      this.form = this.formBuilder.group({
        nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(10)]],
      });
  }

  get f() { return JSON.stringify(this.form.controls); }

  onSubmit() {
    this.submitted = true;
    this.spiner = true;

    if (this.form.invalid) {
      this.spiner = false
      return;
    }
    else {

      if (this.zona == "") {
        this.dataZona.emit(this.form.value.nombre);
      }
      else {
        this.zonaTemp = new ZonaTemporal(this.zona,this.form.value.nombre);
        this.dataZona.emit(this.zonaTemp);
      }
      this.submitted = false;

      setTimeout(() => {
        this.spiner = false;
        this.form.reset();
      }, 1500);
    }
  }
}

