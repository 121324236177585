export class Usuario {
    constructor(
        public id?: number,
        public nombre?: string,
        public apellidos?: string,
        public correo?: string,
        public telefono?: string,
        public habilitado?: boolean,
        public idRol?: string,
        public rol?: string,
        public contrasena?: any,
        public token?: string,
        public avatar?: string,
        public arbol?: string
    ){}
}