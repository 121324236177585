import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { HttpClient as Http, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/config/config.service';
import { Router } from '@angular/router';
import { Usuario } from '../shared/models/usuario.model';
import { Password } from '../shared/models/password.model';
import { URL_Mailing, URL_ServUser } from '../config/config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<Usuario>(null);

  constructor(
    private http: HttpClientService,
    private httpd: Http,
    private router: Router,
    private config: ConfigService,
  ) { }

  login(credential: Usuario, call) {
    this.http.post(`${URL_ServUser}/login`, credential)
      .subscribe((res: any) => {
        if (!res)
          console.error('¡Credenciales incorrectas!');
        else {
          this.user.next(new Usuario(null, res.usuario.nombre, res.usuario.apellidos, res.usuario.correo, null, null, null, null, null, res.usuario.token, null));
          localStorage.d = res.usuario.token;
          this.config.setLocal();
        }
        call(res ? true : false)
      }, (err) => {
        console.error(err);
      });
  }
  forgotUser(credencial: any) {
    return this.http.get(`${URL_ServUser}/getuser?usuario=${credencial.correo}`)
      .pipe(map((res: any) => {
        if (res && res.usuario.correo !== undefined || res.usuario.correo !== null) {
          const dataMailing = {
            sendTo: res.usuario.correo,
            sendMesage: {
              nameuser: res.usuario.nombre,
              username: res.usuario.correo,
              password: res.usuario.contrasena,
              urlplatform: 'https://macad.aguapuebla.mx/login'
            },
            sendPlatform: 'MACAD'
          };
          return dataMailing;
        }
        return true;
      }));
  }

  resetPasswordMailing(dataMailing: any) {
    return this.httpd.post(`${URL_Mailing}mail/reset/macad`, dataMailing);
  }

  getUser() {
    this.http.get(`${URL_ServUser}/user`)
      .subscribe((res: any) => {
        if (res) {
          this.user.next(new Usuario(null, res.usuario.nombre, res.usuario.apellidos, res.usuario.correo, res.usuario.telefono, res.usuario.habilitado, res.usuario.rol.id, res.usuario.rol.nombre, null, null, res.usuario.avatar ? `/assets/avatar/${res.usuario.avatar}.svg` : '/assets/avatar/00.svg', res.usuario.arbol));
          this.config.setLocal();
        }
      }, (err) => {
        this.config.clearLocal();
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  putPassword(data: Password, call) {
    this.http.post(`${URL_ServUser}/updatepassword`, { data: data })
      .subscribe((res: any) => {
        if (!res)
          console.error('¡Credenciales incorrectas!');
        call(res ? true : false)
      }, (err) => {
        console.error(err);
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  logoff() {
    this.http.get(`${URL_ServUser}/logout`)
      .subscribe({
        complete: () => {

          this.user.next(null);
          localStorage.clear();
          this.router.navigate(['/login'], { replaceUrl: true });
        }
      });
  }
}
