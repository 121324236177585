import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { TreeNode } from 'primeng/api';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
  providers: [MessageService]
})

export class UsuarioComponent implements OnInit {

  user: Usuario;
  tabActive: string;

  roles: any[];
  selectedRol: any;

  permisos: any;
  arbol: TreeNode[];
  saveArbol: string = "";

  constructor(
    private userServices: UsuarioService,
    private messageService: MessageService,
    private route: Router
  ) {
    this.tabActive = "TabRol&Permisos";
  }

  ngOnInit() {
    this.userServices.user.subscribe(d => {
      if (d) {
        this.user = d;
        this.permisos = JSON.parse(this.user.arbol);

        this.roles = [
          { name: 'Administrador', id: '1' },
          { name: 'Jefe', id: '2' },
          { name: 'Encargado de Zona', id: '3' },
          { name: 'Proveedor', id: '4' }
        ];

        this.selectedRol =

          setTimeout(() => {
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Cargando', detail: `Usuario: ${this.user.correo}`, life: 1000, closable: false });
          }, 500);
      } else {
        setTimeout(() => {
          if (this.user)
            this.route.navigate([`/usuarios`]);
        }, 500);
      }
    });
  }

  changeRol() {
    if (this.selectedRol) {
      switch (this.selectedRol.name) {
        case "Administrador":
          this.arbol = [
            { "label": "Gráficas" },
            { "label": "Mapa" },
            { "label": "Notificaciones" },
            { "label": "Usuarios" },
            { "label": "Zonas" },
            { "label": "Reportes" },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        case "Jefe":
          this.arbol = [
            { "label": "Gráficas" },
            { "label": "Mapa" },
            { "label": "Usuarios" },
            { "label": "Zonas" },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        case "Encargado de Zona":
          this.arbol = [
            { "label": "Mapa" },
            {
              "label": "Proveedor", "children": [
                { "label": "INCOTEX" },
                { "label": "MARFEL" }
              ]
            },
            {
              "label": "Zonas", "children": [
                { "label": "Zona 1" },
                { "label": "Zona 2" },
                { "label": "Zona 3" },
                { "label": "Zona 4" },
                { "label": "Zona 5" },
                { "label": "Zona 6" },
                { "label": "Zona 7" },
                { "label": "Zona 8" },
                { "label": "Zona 9" },
                { "label": "Zona 10" },
                { "label": "Zona 11" },
                { "label": "Zona 12" },
                { "label": "Zona 13" }
              ]
            },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        case "Proveedor":
          this.arbol = [
            { "label": "Mapa" },
            {
              "label": "Zonas", "children": [
                { "label": "Zona 1" },
                { "label": "Zona 2" },
                { "label": "Zona 3" },
                { "label": "Zona 4" },
                { "label": "Zona 5" },
                { "label": "Zona 6" },
                { "label": "Zona 7" },
                { "label": "Zona 8" },
                { "label": "Zona 9" },
                { "label": "Zona 10" },
                { "label": "Zona 11" },
                { "label": "Zona 12" },
                { "label": "Zona 13" }
              ]
            },
            { "label": "Deshabilitar Dispositivos" }
          ];
          break;

        default:
          break;
      }
    }
  }

  putUser() {
    if (this.roles) {

      if (this.arbol) {

        this.customTree();
        if (this.saveArbol != "[]") {
          let _oldTree = JSON.stringify(this.permisos);
          let _newTree = this.saveArbol;

          if (_newTree != _oldTree) {
            Swal.fire({
              title: `¿Quieres actualizar este Usuario?`,
              html: `Usuario: ${this.user.nombre} ${this.user.apellidos}`,
              icon: "info",
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: 'Actualizar',
              cancelButtonText: 'Cancelar'
            })
              .then((willOK) => {
                if (willOK) {
                  let putUsuario = {
                    usuario: this.user.id,
                    rol: this.selectedRol.name,
                    navegacion: this.saveArbol
                  };

                  this.userServices.putUser(putUsuario);
                }
                else {
                  this.messageService.add({ key: 'toast', severity: 'error', summary: 'Accion cancelada', detail: `No se actualizao el Usuario`, life: 1000, closable: false });
                }
              });
          }
          else
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Denegado', detail: `Seleccionado los mismos permisos`, life: 1000, closable: false });
        }
        else
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Denegado', detail: `No has seleccionado ningun permiso`, life: 1000, closable: false });

      }
      else
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Denegado', detail: `No has actualizado nada`, life: 1000, closable: false });
    }
    else
      this.route.navigate([`/usuarios`]);
  }

  cancelar() {
    this.route.navigate([`/usuarios`]);
  }

  deleteUser() {
    if (this.user) {
      Swal.fire({
        title: "¿Quieres borrar este Usuario?",
        html: `Usuario: ${this.user.nombre} ${this.user.apellidos}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Borrar',
        cancelButtonText: 'Cancelar'
      })
        .then((willOK) => {
          if (willOK) {
            this.userServices.deleteUser(this.user.id);
          } else {
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acción cancelada', detail: `No se borro el usuario`, life: 2000, closable: false });
          }
        });
    }
    else
      this.route.navigate([`/usuarios`]);
  }

  nodeSelect(event) {
    this.findNodo(event.node, true);
  }

  findNodo(node: any, checked: boolean) {
    try {
      if (node.children && node.children.length > 0) {
        node.partialSelected = checked;
        for (let i = 0; i < node.children.length; i++) {
          node.children[i].partialSelected = checked;
          this.findNodo(node.children[i], checked);
        }
      }
      else {
        node.partialSelected = checked;
      }
    } catch (e) {
      console.error();
    }
  }

  customTree() {
    let _arbol: any[] = [];
    this.arbol.map(nodo => {
      if (nodo.partialSelected && nodo.partialSelected == true && nodo.children && nodo.children.length > 0) {
        let _hijos: any = [];
        _arbol.push({ pantalla: nodo.label });
        for (let i = 0; i < nodo.children.length; i++) {
          if (nodo.children[i].partialSelected && nodo.children[i].partialSelected == true)
            _hijos.push(nodo.children[i].label);
        }
        let _index = _arbol.findIndex((f) => { return (f.pantalla == nodo.label) });
        _arbol[_index] = { pantalla: nodo.label, hijos: _hijos }
      }
      else {
        if (nodo.partialSelected && nodo.partialSelected == true)
          _arbol.push({ pantalla: nodo.label });
      }
    });

    this.saveArbol = JSON.stringify(_arbol);
  }

  changeTab(_tab: string) {
    let tabs = document.querySelector("[data-list='tabs']").childNodes;
    Object.values(tabs).map((tab: any) => {
      if (tab.id == _tab) {
        this.tabActive = _tab;
        tab.classList.add("active");
      }
      else
        tab.classList.remove("active");
    });
  }

}
