import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  showSideUser = new BehaviorSubject<boolean>(false);
  showSideSMS = new BehaviorSubject<boolean>(false);
  showSideAlertas = new BehaviorSubject<boolean>(false);
  showLoading = new BehaviorSubject<boolean>(false);
  showSideRigth = new BehaviorSubject<boolean>(false);
  pagina = new BehaviorSubject<string>("");
  stepAddUser = new BehaviorSubject<number>(null);
  colores = ['#ff5252', '#FF4081', '#E040FB', '#7C4DFF', '#536DFE', '#448AFF', '#40C4FF'];
  sound = new BehaviorSubject<boolean>(false);
  existe = new BehaviorSubject<boolean>(false);

  SonidoAlarmaSW = new BehaviorSubject<boolean>(null);
  sountMuted = new BehaviorSubject<boolean>(false);


  llave: string = "0123456789123456";

  //HIGHCHART
  zoomChartStock = { zoomType: "xy" };
  legendChartStock = {
    itemStyle: {
      color: "#fff"
    },
    enabled: true,
    align: "right",
    backgroundColor: "#2e2b2b",
    borderColor: "#000000",
    borderWidth: 2,
    layout: "vertical",
    verticalAlign: "top",
    y: 100,
    shadow: true
  };
  BotonesChartStock = [
    {
      type: 'hour',
      count: 1,
      text: '1h'
    }, {
      type: 'hour',
      count: 2,
      text: '2h'
    }, {
      type: 'hour',
      count: 3,
      text: '3h'
    }, {
      type: "day",
      count: 1,
      text: "1d"
    }, {
      type: "day",
      count: 2,
      text: "2d"
    }, {
      type: "day",
      count: 3,
      text: "3d"
    }, {
      type: "week",
      count: 1,
      text: "1s"
    }, {
      type: "week",
      count: 2,
      text: "2s"
    }, {
      type: "month",
      count: 1,
      text: "1m"
    }, {
      type: "month",
      count: 2,
      text: "2m"
    }, {
      type: "all",
      text: "Todo"
    }
  ];
  yAxisChartStockLogger = [
    {
      title: {
        text: "Presion",
        style: {
          color: "#000"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#000"
        }
      }
    }
  ];
  yAxisChartStockControl = [
    {
      title: {
        text: "Caudal",
        style: {
          color: "#40C4FF"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#40C4FF"
        }
      },
      opposite: false
    },
    {
      title: {
        text: "Presion ⇡",
        style: {
          color: "#76FF03"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#76FF03"
        }
      }
    },
    {
      title: {
        text: "Presion ↓",
        style: {
          color: "#FF6E40"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#FF6E40"
        }
      }
    }
  ];
  yAxisChartStockMultilogUno = [
    {
      title: {
        text: "Presion ⇡",
        style: {
          color: "#76FF03"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#76FF03"
        }
      },
      opposite: false
    },
    {
      title: {
        text: "Presion ↓",
        style: {
          color: "#FF6E40"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#FF6E40"
        }
      }
    }
  ];
  yAxisChartStockMultilogDos = [
    {
      title: {
        text: "Caudal",
        style: {
          color: "#40C4FF"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#40C4FF"
        }
      },
      opposite: false
    },
    {
      title: {
        text: "Presion",
        style: {
          color: "#76FF03"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#76FF03"
        }
      }
    }
  ];
  StyleMapaDia = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "hue": "#ff4400"
        },
        {
          "saturation": -68
        },
        {
          "lightness": -4
        },
        {
          "gamma": 0.72
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon"
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry",
      "stylers": [
        {
          "hue": "#0077ff"
        },
        {
          "gamma": 3.1
        }
      ]
    },
    {
      "featureType": "water",
      "stylers": [
        {
          "hue": "#00ccff"
        },
        {
          "gamma": 0.44
        },
        {
          "saturation": -33
        }
      ]
    },
    {
      "featureType": "poi.park",
      "stylers": [
        {
          "hue": "#44ff00"
        },
        {
          "saturation": -23
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "hue": "#007fff"
        },
        {
          "gamma": 0.77
        },
        {
          "saturation": 65
        },
        {
          "lightness": 99
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "gamma": 0.11
        },
        {
          "weight": 5.6
        },
        {
          "saturation": 99
        },
        {
          "hue": "#0091ff"
        },
        {
          "lightness": -86
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": -48
        },
        {
          "hue": "#ff5e00"
        },
        {
          "gamma": 1.2
        },
        {
          "saturation": -23
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "saturation": -64
        },
        {
          "hue": "#ff9100"
        },
        {
          "lightness": 16
        },
        {
          "gamma": 0.47
        },
        {
          "weight": 2.7
        }
      ]
    }
  ];
  StyleMapaNoche = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];

  constructor(
    public datepipe: DatePipe
  ) { }

  getStatusPantalla(pagina?: string): number {
    let width = window.screen.width;
    switch (pagina) {
      case "cards": {
        if (width < 640) return 1;
        else if (width > 640 && width < 1920) return 5;
        else return 8;
      }
      default: {
        if (width < 640) return 1;
        else if (width > 640 && width < 1920) return 10;
        else return 17;
      }
    }
  }

  getDataTable(data: any): any {
    let arregloCols: any[] = [];
    let headers = Object.keys(data[0]);

    headers.map(h => {
      if (h != "id")
        arregloCols.push({
          field: h,
          header: h
        });
    });

    let arregloRows: any[] = [];
    data.map((row) => {
      let x: any = [];
      headers.map((h) => {
        if (h == "registrado")
          x[h] = String(this.datepipe.transform(row[h], 'fullDate'))
        else if (h == "fecha")
          x[h] = String(this.datepipe.transform(row[h], 'short'))
        else if (h == "habilitado")
          x[h] = Boolean(row[h])
        else
          x[h] = String(row[h]);
      });
      arregloRows.push(x)
    });

    let datosFiltro: any[] = [];
    let tempFiltros: any[] = [];

    headers.map(h => {
      let temp: any[] = [];
      arregloRows.map(r => {
        temp.push({ label: String(r[h]), value: String(r[h]) });
      });
      datosFiltro.push(temp);
    });

    datosFiltro.map((casilla, i) => {
      let temp = this.removeDuplicates(casilla, "label");
      tempFiltros.push({
        casilla: headers[i],
        lista: temp,
        label: `${headers[i].toUpperCase()}`
      });
    });

    return { cols: arregloCols, rows: arregloRows, filtros: tempFiltros };
  }

  removeDuplicates(array, propiedad) {
    var newArray = [];
    var busca = {};

    for (var i in array) {
      busca[array[i][propiedad]] = array[i];
    }

    for (i in busca) {
      newArray.push(busca[i]);
    }
    return newArray;
  }

  getSizeFile(file): string {
    const k = 1024;
    const dm = 2 < 0 ? 0 : 2;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(file.size) / Math.log(k));

    return parseFloat((parseInt(file.size) / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
