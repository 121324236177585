import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-las-envio',
  templateUrl: './las-envio.component.html',
  styleUrls: ['./las-envio.component.css']
})
export class LasEnvioComponent implements OnChanges {

  @Input() dataRegistro: any;
  registro: any;
  
  constructor() { }

  ngOnChanges() {
    if (this.dataRegistro)
      this.registro = this.dataRegistro;
  }
}
