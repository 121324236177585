import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { BehaviorSubject } from 'rxjs';
import { VariablesService } from './variableGL.service';
import { Alerta } from '../shared/models/alerta.model';
import { URL_ServDevice } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class AlertasService {

    alertasActivas = new BehaviorSubject<Alerta[]>(null);
    alertasHistorico = new BehaviorSubject<Alerta[]>(null);

    constructor(
        private http: HttpClientService,
        private variablesGL: VariablesService,
    ) { }

    getAlertasActivas() {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServDevice}/activealerts`)
            .subscribe((res: any) => {
                if (res) {
                    this.alertasActivas.next(res);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {

            });
    }

    getAlertasHistorico() {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServDevice}/historyalerts`)
            .subscribe((res: any) => {
                if (res) {
                    this.alertasHistorico.next(res);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {

            });
    }

}
