export class alertMessage {

    constructor(
        public alertClass?: string,
        public alertShow?: boolean,
        public alertMessage?: string,
        public alertHeader?: string,
        public alertDetails?: string
    ) {

    }
}