import Swal from 'sweetalert2';
import { Component, OnInit, HostListener, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { VariablesService } from '../services/variableGL.service';
import { flipInXAnimation } from '../shared/animations/flipinX';
import { fadeAnimation } from '../shared/animations/fade';
import { SlideAnimation } from '../shared/animations/slide';
import { DashBoardService } from '../services/dashboard.service';
import { AuthService } from '../services/auth.service';
import { Alerta } from '../shared/models/alerta.model';
//import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
  animations: [flipInXAnimation, fadeAnimation, SlideAnimation],
})

export class PagesComponent implements OnInit {
  @ViewChild('audioOption', { static: false, }) audioPlayerRef: ElementRef;
  @ViewChild('audioOption2', { static: false, }) audioPlayerRef2: ElementRef;

  mostrarSideUser: boolean;
  mostrarSideSMS: boolean;
  mostrarSideAlertas: boolean;
  mostrarLoading: boolean;
  mostrarSideRigth: boolean;
  StatusSonidoAlarma: boolean;

  contador: number = 0;
  contadorSMS: number = 0;
  contadorAlertas: number = 0;
  contadorSideRigth: number = 0;

  intervalo: any = null;
  interval: any = null;

  constructor(
    private variables: VariablesService,
    private cdRef: ChangeDetectorRef,
    private dashbaordService: DashBoardService,
    //private messageService: MessageService,
    private auth: AuthService,
    private variablesGL: VariablesService
  ) {
    this.variables.showSideUser.subscribe(value => {
      this.mostrarSideUser = value;
    });
    this.variables.showSideSMS.subscribe(value => {
      this.mostrarSideSMS = value;
    });
    this.variables.showSideAlertas.subscribe(value => {
      this.mostrarSideAlertas = value;
    });
    this.variables.showLoading.subscribe(value => {
      this.mostrarLoading = value;
    });
    this.variables.showSideRigth.subscribe(value => {
      this.mostrarSideRigth = value;
    });
    this.variablesGL.sound.subscribe(estatus => {
      if (estatus) {
        this.onAudioPlay();
      }
      else if (estatus != null && estatus == false) {
        this.onStop();
      }
    });

    this.variablesGL.SonidoAlarmaSW.subscribe(alarma => {
      this.StatusSonidoAlarma = alarma;
      if (this.StatusSonidoAlarma) {
        this.onAudioPlay2();
        console.log("Sonido Activado...");

      } else if (this.StatusSonidoAlarma != null && this.StatusSonidoAlarma == false) {
        this.onStop2();
        console.log("Sonido Desactivado...");

      }
    })
  }

  ngOnInit() {
    this.auth.getUser();
    this.dashbaordService.getAlertas();
  }

  onAudioPlay() {
    this.intervalo = setInterval(() => {
      let _alertas = this.dashbaordService.alertas.value;
      if (_alertas) {
        let _activa = _alertas.find((a: Alerta) => a.estatus == true);

        if (_activa != undefined) {
          this.audioPlayerRef.nativeElement.play();
        }
      }
    }, 3000);
  }

  onAudioPlay2() {
    this.audioPlayerRef2.nativeElement.play();
  }

  onStop2() {
    this.audioPlayerRef2.nativeElement.pause();
  }

  onStop() {
    clearInterval(this.intervalo);
  }

  openSidebar() {
    let sidebar = document.querySelector(".page-wrapper");
    sidebar.classList.add("toggled");
  }
  closeSidebar() {
    let sidebar = document.querySelector(".page-wrapper");
    sidebar.classList.remove("toggled");
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  //#region Modales
  clickSides($event: Event) {
    $event.preventDefault(); // Cancela el evento si este es cancelable, sin detener el resto del funcionamiento del evento, es decir, puede ser llamado de nuevo
    $event.stopPropagation();  // Evita la propagación adicional del evento actual en las fases de captura y bubbling
  }

  @HostListener('document:click', ['$event']) clickedOutside($event) {
    if (this.mostrarSideUser && this.contador == 0) {
      this.mostrarSideUser = true;
      this.mostrarSideSMS = false;
      this.mostrarSideAlertas = false;
      this.mostrarSideRigth = false;
      this.contadorSMS = 0;
      this.contadorAlertas = 0;
      this.contadorSideRigth = 0;
      this.contador++;
    }
    else if (this.mostrarSideUser && this.contador == 1) {
      this.emptySides();
    }
    else if (this.mostrarSideSMS && this.contadorSMS == 0) {
      this.mostrarSideUser = false;
      this.mostrarSideSMS = true;
      this.mostrarSideAlertas = false;
      this.mostrarSideRigth = false;
      this.contadorSMS++;
      this.contadorAlertas = 0;
      this.contadorSideRigth = 0;
      this.contador = 0;
    }
    else if (this.mostrarSideSMS && this.contadorSMS == 1) {
      this.emptySides();
    }
    else if (this.mostrarSideAlertas && this.contadorAlertas == 0) {
      this.mostrarSideUser = false;
      this.mostrarSideSMS = false;
      this.mostrarSideAlertas = true;
      this.mostrarSideRigth = false;
      this.contadorSMS = 0;
      this.contadorAlertas++;
      this.contadorSideRigth = 0;
      this.contador = 0;
    }
    else if (this.mostrarSideAlertas && this.contadorAlertas == 1) {
      this.emptySides();
    }
    else if (this.mostrarSideRigth && this.contadorSideRigth == 0) {
      this.mostrarSideUser = false;
      this.mostrarSideSMS = false;
      this.mostrarSideAlertas = false;
      this.mostrarSideRigth = true;
      this.contadorSMS = 0;
      this.contadorAlertas = 0;
      this.contadorSideRigth++;
      this.contador = 0;
    }
    else if (this.mostrarSideRigth && this.contadorSideRigth == 1) {
      this.emptySides();
    }
  }

  @HostListener('click') onClick() {
    let sound = this.variablesGL.SonidoAlarmaSW.value;
    if (sound) {
      Swal.fire({
        title: `¿Quieres desactivar el sonido de las Alarmas?`,
        html: `Este sonido se desactivará por 4 minutos y se reactivará automáticamente cuando lleguen nuevas Alarmas`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Desactivar',
        cancelButtonText: 'Cancelar'
      })
        .then((willOK) => {
          if (willOK) {
            this.variablesGL.SonidoAlarmaSW.next(false);
            this.variablesGL.sountMuted.next(true);

            setTimeout(() => {
              this.variablesGL.sountMuted.next(false);
            }, 240000);
          }
          else {
            console.log("Accion cancelada");
          }
        });
    }

  }

  emptySides() {
    this.mostrarSideUser = false;
    this.mostrarSideSMS = false;
    this.mostrarSideAlertas = false;
    this.mostrarSideRigth = false;
    this.contadorSMS = 0;
    this.contadorAlertas = 0;
    this.contadorSideRigth = 0;
    this.contador = 0;
  }
  //#endregion

}
