import { Component, EventEmitter, OnInit, ViewChild, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Usuario } from 'src/app/shared/models/usuario.model';

@Component({
  selector: 'app-rpassword',
  templateUrl: './rpassword.component.html',
  styleUrls: ['./rpassword.component.css']
})
export class RpasswordComponent implements OnInit {

  @ViewChild('myForm', { static: false, }) formValues;
  form: FormGroup;
  submitted = false;

  spiner: boolean = false;
  BbtnValidar: boolean = false;

  @Output() dataUser = new EventEmitter<Usuario>();

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      correo: ['', [Validators.required, Validators.email]],
    });
    this.BbtnValidar = false;
  }

  get f() { return JSON.stringify(this.form.controls); }

  onSubmit() {
    this.submitted = true;
    this.spiner = true;

    if (this.form.invalid) {
      this.spiner = false
      return;
    }
    else {
      this.dataUser.emit(new Usuario(null, null, null, this.form.value.correo, null, null, null, null, null, null, null));
      this.submitted = false;

      setTimeout(() => {
        this.spiner = false
        this.BbtnValidar = true;
      }, 2500);
    }
  }

}
