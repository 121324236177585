import { Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DispositivoService } from 'src/app/services/dispositivo.service';
import { AuthService } from 'src/app/services/auth.service';
import { Usuario } from '../../models/usuario.model';

@Component({
  selector: 'app-alarma',
  templateUrl: './alarma.component.html',
  styleUrls: ['./alarma.component.css']
})
export class AlarmaComponent implements OnChanges {

  @Input() dataAlarma: any;
  @Output() alarma = new EventEmitter<any>();

  @ViewChild('myFormPresion', { static: false, }) formValuesPresion;
  @ViewChild('myFormArriba', { static: false, }) formValuesArriba;
  @ViewChild('myFormAbajo', { static: false, }) formValuesAbajo;
  @ViewChild('myFormCaudal', { static: false, }) formValuesCaudal;
  formPresion: FormGroup;
  formArriba: FormGroup;
  formAbajo: FormGroup;
  formCaudal: FormGroup;
  submittedPresion = false;
  submittedArriba = false;
  submittedAbajo = false;
  submittedCaudal = false;
  spinerPresion: boolean = false;
  spinerCaudal: boolean = false;
  spinerArriba: boolean = false;
  spinerAbajo: boolean = false;

  visibleChange: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dispositivoServicio: DispositivoService,
    private auth: AuthService
  ) {
    this.formPresion = this.fb.group({
      maximo: [null, [Validators.required]],
      minimo: [null, [Validators.required]],
    });

    this.formArriba = this.fb.group({
      maximo: [null, [Validators.required]],
      minimo: [null, [Validators.required]],
    });

    this.formAbajo = this.fb.group({
      maximo: [null, [Validators.required]],
      minimo: [null, [Validators.required]],
    });

    this.formCaudal = this.fb.group({
      maximo: [null, [Validators.required]],
      minimo: [null, [Validators.required]],
    });

    this.dispositivoServicio.alarmas.subscribe(r => {
      if (r) {
        r.listaAlarmas.map(x => {
          if (x.tipo == "Presión") {
            this.formPresion.controls.maximo.setValue(x.presion.maximo);
            this.formPresion.controls.minimo.setValue(x.presion.minimo);
          }
          else if (x.tipo == "Caudal") {
            this.formCaudal.controls.maximo.setValue(x.caudal.maximo);
            this.formCaudal.controls.minimo.setValue(x.caudal.minimo);
          }
          else if (x.tipo == "Presión Aguas Arriba") {
            this.formArriba.controls.maximo.setValue(x.presionArriba.maximo);
            this.formArriba.controls.minimo.setValue(x.presionArriba.minimo);
          }
          else if (x.tipo == "Presión Agua Abajo") {
            this.formAbajo.controls.maximo.setValue(x.presionAbajo.maximo);
            this.formAbajo.controls.minimo.setValue(x.presionAbajo.minimo);
          }
        });
      }
    });

    this.auth.user.subscribe((data: Usuario) => {
      if (data) {
        if (data.arbol != undefined) {
          this.visibleChange = data.rol == "Administrador" ? true : false;
        }
      }
    });
  }

  ngOnChanges() {
    if (this.dataAlarma) {
    }
  }

  onSubmitPresion() {
    this.submittedPresion = true;
    this.spinerPresion = true;

    if (this.formPresion.invalid) {
      this.spinerPresion = false
      return;
    }
    else {
      this.submittedPresion = false;
      this.alarma.emit({
        canal: "Presión",
        datos: this.formValuesPresion.value
      });

      setTimeout(() => {
        this.spinerPresion = false
      }, 1000);
    }
  }

  onSubmitArriba() {
    this.submittedArriba = true;
    this.spinerArriba = true;

    if (this.formArriba.invalid) {
      this.spinerArriba = false
      return;
    }
    else {
      this.submittedArriba = false;
      this.alarma.emit({
        canal: "Presión Aguas Arriba",
        datos: this.formValuesArriba.value
      });

      setTimeout(() => {
        this.spinerArriba = false
      }, 1000);
    }
  }

  onSubmitAbajo() {
    this.submittedAbajo = true;
    this.spinerAbajo = true;

    if (this.formAbajo.invalid) {
      this.spinerAbajo = false;
      return;
    }
    else {
      this.submittedAbajo = false;
      this.alarma.emit({
        canal: "Presión Agua Abajo",
        datos: this.formValuesAbajo.value
      });

      setTimeout(() => {
        this.spinerAbajo = false
      }, 1000);
    }
  }

  onSubmitCaudal() {
    this.submittedCaudal = true;
    this.spinerCaudal = true;

    if (this.formCaudal.invalid) {
      this.spinerCaudal = false;
      return;
    }
    else {
      this.submittedCaudal = false;
      this.alarma.emit({
        canal: "Caudal",
        datos: this.formValuesCaudal.value
      });

      setTimeout(() => {
        this.spinerCaudal = false
      }, 1000);
    }
  }

}
