import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RoutesModule, RouterComponents } from '../config/routes.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// PAGINAS
import { LoginComponent } from './pages/login/login.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { PagesComponent } from './pages/pages.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RolesComponent } from './pages/roles/roles.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { ActivasComponent } from './pages/notificaciones/activas/activas.component';
import { HistoicoComponent } from './pages/notificaciones/histoico/histoico.component';
import { MapaComponent } from './pages/mapa/mapa.component';
import { ZonasComponent } from './pages/zonas/zonas.component';
import { CargarComponent } from './pages/notificaciones/cargar/cargar.component';
// SHARED
import { NavBarComponent } from './shared/components/nav-bar/nav-bar.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { ContenidoComponent } from './shared/components/contenido/contenido.component';
import { SideUserComponent } from './shared/components/side-user/side-user.component';
import { SideMessagesComponent } from './shared/components/side-messages/side-messages.component';
import { SideAlertasComponent } from './shared/components/side-alertas/side-alertas.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { SideRightComponent } from './shared/components/side-right/side-right.component';
import { ProveedoresComponent } from './shared/components/proveedores/proveedores.component';
import { EnvioComponent } from './shared/components/charts/envio/envio.component';
import { AlarmaComponent } from './shared/components/alarma/alarma.component';

//PIPES
import { PhonePipe } from './shared/pipes/telefono.pipe';
//CHARTS
import { BarrasComponent } from './shared/components/charts/barras/barras.component';
import { BarrasZComponent } from './shared/components/charts/barras-zona/barras-zona.component';
import { DonutComponent } from './shared/components/charts/donut/donut.component';
import { GaugeComponent } from './shared/components/charts/gauge/gauge.component';
import { CardComponent } from './shared/components/charts/card/card.component';
import { LineAreaComponent } from './shared/components/charts/line-area/line-area.component';
import { LasEnvioComponent } from './shared/components/charts/las-envio/las-envio.component';
//AREA
import { LoggerComponent } from './shared/components/charts/area/logger/logger.component';
import { ControlComponent } from './shared/components/charts/area/control/control.component';
import { MultilogUnoComponent } from './shared/components/charts/area/multilog-uno/multilog-uno.component';
import { MultilogDosComponent } from './shared/components/charts/area/multilog-dos/multilog-dos.component';
// MODALS
import { ListaDispositivosComponent } from './shared/components/modals/lista-dispositivos/lista-dispositivos.component';
import { MdescripcionComponent } from './shared/components/modals/mdescripcion/mdescripcion.component';
// FORMULARIOS
import { FaddUserComponent } from './shared/components/forms/fadd-user/fadd-user.component';
import { FloginComponent } from './shared/components/forms/flogin/flogin.component';
import { FpasswordComponent } from './shared/components/forms/fpassword/fpassword.component';
import { FaddZonaComponent } from './shared/components/forms/fadd-zona/fadd-zona.component';
// TABLE
import { RegistrosComponent } from './shared/components/table/registros/registros.component';
// REPORTE
import { RdispositivosComponent } from './pages/reporte/rdispositivos/rdispositivos.component';
// NG
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { PasswordModule } from 'primeng/password';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { GMapModule } from 'primeng/gmap';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from "primeng/multiselect";
import { DialogModule } from "primeng/dialog";
import { PickListModule } from 'primeng/picklist';
import { DropdownModule } from 'primeng/dropdown';
import { TreeModule } from 'primeng/tree';
import { StepsModule } from 'primeng/steps';
import { SidebarModule } from 'primeng/sidebar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
// Idioma Español
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
registerLocaleData(localeEsAr, 'es-Ar');
//Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RpasswordComponent } from './shared/components/forms/rpassword/rpassword.component';

@NgModule({
  declarations: [
    AppComponent,
    RouterComponents,
    LoginComponent,
    RpasswordComponent,
    PagesComponent,
    NavBarComponent,
    MenuComponent,
    ContenidoComponent,
    SideUserComponent,
    SideMessagesComponent,
    SideAlertasComponent,
    DashboardComponent,
    BreadcrumbComponent,
    LoadingComponent,
    SideRightComponent,
    FaddUserComponent,
    PerfilComponent,
    RolesComponent,
    UsuarioComponent,
    UsuariosComponent,
    AddUserComponent,
    FloginComponent,
    FpasswordComponent,
    ActivasComponent,
    HistoicoComponent,
    //PIPES
    PhonePipe,
    //CHARTS
    BarrasComponent,
    BarrasZComponent,
    DonutComponent,
    GaugeComponent,
    CardComponent,
    EnvioComponent,
    ProveedoresComponent,
    ListaDispositivosComponent,
    MdescripcionComponent,
    LineAreaComponent,
    LoggerComponent,
    LasEnvioComponent,
    RegistrosComponent,
    ControlComponent,
    MultilogUnoComponent,
    MultilogDosComponent,
    MapaComponent,
    AlarmaComponent,
    RdispositivosComponent,
    ZonasComponent,
    FaddZonaComponent,
    CargarComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    RoutesModule,
    HttpClientModule,
    // NG
    InputTextModule,
    InputSwitchModule,
    ButtonModule,
    TooltipModule,
    PasswordModule,
    InputMaskModule,
    ToastModule,
    TieredMenuModule,
    PanelMenuModule,
    GMapModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    PickListModule,
    DropdownModule,
    TreeModule,
    StepsModule,
    SidebarModule,
    ScrollPanelModule,
    RadioButtonModule,
    ProgressBarModule,

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es-Ar' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
