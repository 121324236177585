import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { VariablesService } from './variableGL.service';
import { URL_ServReports } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class ReporteService {

    dispositvos = new BehaviorSubject<object>(null);

    constructor(
        private http: HttpClientService,
        private router: Router,
        private variablesGL: VariablesService
    ) { }

    getDispositivos() {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServReports}/devices`)
            .subscribe((res: any) => {
                if (res) {
                    this.dispositvos.next(res);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    getDispositivosDate(date: string) {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServReports}/devicesdate?date=${date}`)
            .subscribe((res: any) => {
                if (res) {
                    this.dispositvos.next(res);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

}
