import { Component, OnInit } from '@angular/core';
import { AlertasService } from 'src/app/services/alertas.service';
import { VariablesService } from 'src/app/services/variableGL.service';

@Component({
  selector: 'app-histoico',
  templateUrl: './histoico.component.html',
  styleUrls: ['./histoico.component.css']
})
export class HistoicoComponent implements OnInit {

  table: any;

  constructor(
    private alertasService: AlertasService,
    private variablesGL: VariablesService
  ) { 
    this.alertasService.alertasHistorico.subscribe(r => {
      if (r) 
        this.table = this.variablesGL.getDataTable(r);
    });
  }

  ngOnInit() {
    this.alertasService.getAlertasHistorico();
  }
}
