import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { VariablesService } from './variableGL.service';
import { Dispositivo } from '../shared/models/dispositivo.model';
import { Router } from '@angular/router';
import { URL_ServLocation } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class MapaService {

    listaDispositivos = new BehaviorSubject<Dispositivo[]>(null);

    constructor(
        private http: HttpClientService,
        private variablesGL: VariablesService,
        private router: Router
    ) { }

    getUbicaciones(proveedor: string, zona: string) {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_ServLocation}/locations?proveedor=${proveedor}&&zona=${zona}`)
            .subscribe((res: any) => {
                if (res) {
                    if (res.mensaje == 'Exito')
                        this.listaDispositivos.next(res.lista);
                    else
                        this.listaDispositivos.next([]);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }
}
