import { Component, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';
import { fadeAnimation } from '../../animations/fade';
import { AlertasService } from 'src/app/services/alertas.service';
import { MessageService } from 'primeng/api';
import { MetodosSocket } from 'src/app/services/WebSocket/metodosSocket.service';
import { InicioSocketService } from 'src/app/services/WebSocket/inicioSocket.service';

@Component({
  selector: 'app-contenido',
  templateUrl: './contenido.component.html',
  styleUrls: ['./contenido.component.css'],
  animations: [fadeAnimation],
  providers: [MessageService]
})
export class ContenidoComponent implements OnInit {

  pagina:string;
  StatusMuted: boolean;

  constructor(
    private variablesGL: VariablesService,
    private alertasService: AlertasService,
    private messageService: MessageService,
    private metodosSocket: MetodosSocket,
    private wsInit: InicioSocketService,

  ) { 
    this.variablesGL.pagina.subscribe( r => {
      this.pagina = r;
    });

    this.variablesGL.sountMuted.subscribe( muted => {
      this.StatusMuted = muted;
    });

    this.metodosSocket.showAlerta.subscribe(r => {
      if (r) {
        this.messageService.add({key: 'toast', severity:'info', summary: 'Alerta', detail:'Se agrego una nueva Alarma.', life: 1000, closable: false});
        
        if(!this.StatusMuted){
          this.variablesGL.SonidoAlarmaSW.next(true);
        }
      }
    });
  }

  ngOnInit() {
    this.wsInit.init();
    
    this.alertasService.getAlertasHistorico();
    this.alertasService.getAlertasActivas();
  }

}
