import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Carta } from 'src/app/shared/models/card.model';
import { Proveedor } from 'src/app/shared/models/proveedor.model';
import { Dispositivo } from 'src/app/shared/models/dispositivo.model';
import { DashBoardService } from 'src/app/services/dashboard.service';
import { VariablesService } from 'src/app/services/variableGL.service';
import { MessageService } from 'primeng/api';
import Swal from 'sweetalert2';
import { DispositivoService } from 'src/app/services/dispositivo.service';

@Component({
  selector: 'app-lista-dispositivos',
  templateUrl: './lista-dispositivos.component.html',
  styleUrls: ['./lista-dispositivos.component.css'],
  providers: [MessageService]
})
export class ListaDispositivosComponent implements OnChanges, OnInit, OnDestroy {

  @Input() dataCard: [Carta, Proveedor];
  @Input() dispositivoEliminar: [Dispositivo, boolean];
  @Output() dataDispositivo = new EventEmitter<Dispositivo>();
  @Output() cambios = new EventEmitter<boolean>();
  @Output() loggerEliminar = new EventEmitter<Dispositivo>();

  mostrar: boolean;
  carta: Carta;
  proveedor: Proveedor;
  eliminar: Dispositivo;
  response: boolean;
  dataTable: any;
  numRows: number = 5;
  dispositivo: [Dispositivo, boolean];
  statusDispositivo: boolean;

  selectedDispositivo: Dispositivo;

  constructor(
    private dashbaordService: DashBoardService,
    private variablesGL: VariablesService,
    private messageService: MessageService,
    private dispositivoServicio: DispositivoService,
  ) {
    this.dashbaordService.listaDispositivos.subscribe((lista: Dispositivo[]) => {
      if (lista)
        if (lista.length > 0) {
          this.dataTable = this.variablesGL.getDataTable(lista);
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Cargando', detail: `Espera unos segundos`, life: 1000, closable: false });
        }
        else
          this.dataTable = null;
      if (lista != null)
        if (lista.length == 0) {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: `Lista Vacia`, life: 1000, closable: false });
        }
    });
    this.numRows = variablesGL.getStatusPantalla('cards');
  }

  ngOnInit() {
    this.mostrar = this.variablesGL.existe.value;
  }

  ngOnChanges(): void {
    if (this.dataCard) {
      this.carta = this.dataCard[0];
      this.proveedor = this.dataCard[1];
    }
    if (this.dispositivoEliminar) {
      this.eliminar = this.dispositivoEliminar[0];
      this.response = this.dispositivoEliminar[1];

      if (this.dispositivoEliminar[1] == true) {

        if (this.statusDispositivo)
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: "Dispositivo Habilitado", life: 2000, closable: false });
        else
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: "Dispositivo Deshabilitado", life: 2000, closable: false });

        this.deleteRowDispositivo(this.dispositivoEliminar[0]);

      } else if (this.dispositivoEliminar[1] == false) {

        this.messageService.add({ key: 'toast', severity: 'error', summary: '¡Denegado!', detail: 'No se deshabilito el dispositivo', life: 2000, closable: false });
        let encontrado: Dispositivo = this.dataTable.rows.find(u => u.id == this.eliminar.id);
        encontrado.habilitado = !status;

      }
      this.dispositivoEliminar = null;
    }
  }

  ngOnDestroy() {
    this.mostrar = false;
  }

  onRowSelect(event) {
    this.selectedDispositivo = event;
    this.dataDispositivo.emit(new Dispositivo(this.selectedDispositivo.id, this.selectedDispositivo.proveedor, this.selectedDispositivo.zona, this.selectedDispositivo.nombre, this.selectedDispositivo.serial, this.selectedDispositivo.latitud, this.selectedDispositivo.longitud, this.selectedDispositivo.telefono, this.selectedDispositivo.bateriaActual, this.selectedDispositivo.bateriaTotal, this.selectedDispositivo.registro, this.selectedDispositivo.habilitado));
  }

  changeStatus(status, data: Dispositivo) {
    this.statusDispositivo = status;

    Swal.fire({
        title: status ? `¿Quieres Habilitar éste Dispositivo?` : `¿Quieres Deshabilitar éste Dispositivo?`,
        html: `Logger: ${data.nombre}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: status ? "Habilitar" : "Deshabilitar",
        cancelButtonText: 'Cancelar'
    })
      .then((willOK) => {
        if (willOK)
          this.loggerEliminar.emit(new Dispositivo(data.id, data.proveedor, data.zona, data.nombre, data.serial, data.latitud, data.longitud, data.telefono, data.bateriaActual, data.bateriaTotal, data.registro, data.habilitado));
        else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Accion cancelada', detail: `No se cambio el estatus`, life: 2000, closable: false });
          let encontrado: Dispositivo = this.dataTable.rows.find(u => u.id == data.id);
          encontrado.habilitado = !status;
        }
      });
  }

  //#region Borro el dispositivo de la tabla
  deleteRowDispositivo(data: Dispositivo) {
    let indice;
    this.dataTable.rows.map((r, i) => {
      if (r.id == data.id) {
        indice = i;
      }
    });

    if (indice > 0)
      this.dataTable.rows.splice(indice, 1);
    else if (indice == 0)
      this.dataTable.rows.shift();

    this.cambios.emit(true);
  }
  //#endregion

}
