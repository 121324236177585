import { Component, OnInit } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import Swal from 'sweetalert2';
import { RolService } from 'src/app/services/rol.service';
import { Rol } from 'src/app/shared/models/rol.model';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'],
  providers: [MessageService]
})
export class RolesComponent implements OnInit {

  tabActive: string;

  roles: Rol[];
  selectedRol: Rol;

  arbol: TreeNode[];

  sourceUsuarios: any[];
  targetUsuarios: any[];

  constructor(
    private rolService: RolService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.tabActive = "Roles&Permisos";

    this.rolService.getRoles();
    this.rolService.roles.subscribe(d => {
      if (d) {
        this.roles = d;
      }
    });
  }

  changeTab(_tab: string) {
    let tabs = document.querySelector("[data-list='tabs']").childNodes;
    Object.values(tabs).map((tab: any) => {
      if (tab.id == _tab) {
        this.tabActive = _tab;
        tab.classList.add("active");
      }
      else
        tab.classList.remove("active");
    });
  }

  changeRol() {
    if (this.selectedRol) {

      this.arbol = [JSON.parse(this.selectedRol.arbol)];

      this.sourceUsuarios = [
        {
          "id": 4,
          "foto": "assets/avatar/00.svg",
          "nombre": "Hernesto",
          "apellidos": "De la Cruz"
        }, {
          "id": 5,
          "foto": "assets/avatar/01.svg",
          "nombre": "Yasmin",
          "apellidos": "Hernandez",
        }, {
          "id": 1,
          "foto": "assets/avatar/04.svg",
          "nombre": "Alexis",
          "apellidos": "Tamariz",
        }
      ];
      this.targetUsuarios = [];
      this.messageService.add({ key: 'toast', severity: 'info', summary: 'Buscando', detail: `Rol ${this.selectedRol.nombre}`, life: 1000, closable: false });
    }
  }

  nodeSelect(event) {
    this.findNodo(event.node, true);
  }

  findNodo(node: any, checked: boolean) {
    try {
      if (node.children && node.children.length > 0) {
        node.partialSelected = checked;
        for (var i = 0; i < node.children.length; i++) {
          node.children[i].partialSelected = checked;
          this.findNodo(node.children[i], checked);
        }
      }
      else {
        node.partialSelected = checked;
      }
    } catch (e) {
      console.error();
    }
  }

  updateRol() {
    Swal.fire({
      title: `¿Quieres desactivar el sonido de las Alarmas?`,
        html: `Rol: ${this.selectedRol.nombre}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Actualizar',
        cancelButtonText: 'Cancelar'
    })
      .then((willOK) => {
        if (willOK) {
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Actualizando', detail: `Rol: ${this.selectedRol.nombre}`, life: 1000, closable: false });
        } else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acción cancelada', detail: `No se actualizo el Rol: ${this.selectedRol.nombre}`, life: 2000, closable: false });
        }
      });
  }
}

