import { Component, Input, OnChanges } from '@angular/core';

import * as ApexCharts from 'apexcharts';
import { Grouped } from 'src/app/shared/models/grouped.model';

@Component({
  selector: 'app-barras-zona',
  templateUrl: './barras-zona.component.html',
  styleUrls: ['./barras-zona.component.css']
})
export class BarrasZComponent implements OnChanges {

  @Input() grouped: Grouped;
  chartBarras: ApexCharts;

  colores = ['#536DFE', '#40C4FF', '#ff5252', '#E040FB','#64FFDA', '#B2FF59', '#FFD740', '#FF6E40'];

  constructor() { }

  ngOnChanges() {
    if (this.grouped) {
      if (this.chartBarras) {
        this.chartBarras.updateOptions(this.getOptions(),true,true)
      }
      else {
        this.chartBarras = new ApexCharts(document.querySelector("#chartBarrasZ"), this.getOptions());
        this.chartBarras.render();
      }
    }
  }

  getOptions(): any {
    return {
      chart: {
        type: 'bar',
        foreColor: '#848484',
        background: '#fff',
        width: '100%',
        height: 'auto',
        parentHeightOffset: 15
      },
      title: {
        text: 'Dispositivos que Comunican por Zona',
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 10,
        floating: false,
        style: {
          fontSize: '15px'
        },
      },
      fill: {
        opacity: 0.7,
        type: 'solid'
      },
      plotOptions: {
        bar: {
          dataLabels: { position: 'top' },
          columnWidth: `80%`,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -1,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      series: this.grouped.seriesData,
      xaxis: {
        categories: this.grouped.xaxisData
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
              bar: {
                horizontal: false
              }
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }
}


