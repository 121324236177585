import { Component, OnInit } from '@angular/core';
import { ZonasService } from 'src/app/services/zonas.service';
import { VariablesService } from 'src/app/services/variableGL.service';
import { MessageService } from 'primeng/api';
import { ZonaTemporal } from 'src/app/shared/models/zonaTemp.model';
import Swal from 'sweetalert2';
import { Zona } from 'src/app/shared/models/zona.model.1';

@Component({
  selector: 'app-zonas',
  templateUrl: './zonas.component.html',
  styleUrls: ['./zonas.component.css'],
  providers: [MessageService]
})
export class ZonasComponent implements OnInit {

  dataInitial: any;
  dataTable: any;
  modalAddZona: boolean = false;
  modalUpdateZona: boolean = false;
  selectedZona: string = null;

  constructor(
    private zonasService: ZonasService,
    private variablesGL: VariablesService,
    private messageService: MessageService
  ) {
    this.zonasService.zonas.subscribe(data => {
      if (data) {
        this.dataInitial = data;
        if (this.dataInitial.listZonas.length > 0) {
          this.dataTable = this.variablesGL.getDataTable(this.dataInitial.listZonas);
        }
        else
          this.dataTable = null;
      }
    });

    this.zonasService.zonaNueva.subscribe((d: Zona) => {
      if (d) {

        let existeZona = this.zonasService.zonas.value.listZonas.find(x => x.nombre == d.nombre);

        if (!existeZona) {
          let primero = {
            id: d.id,
            nombre: d.nombre,
            cantidad: 0,
            estatus: true
          };

          this.dataInitial.listZonas.unshift(primero)
          this.zonasService.zonas.next(this.dataInitial);
        }
      }
    });

    this.zonasService.zonaEliminada.subscribe(idZona => {
      if (idZona) {
        let existeZona = this.zonasService.zonas.value.listZonas.find(x => x.id == idZona);

        if (existeZona) {
          let indice;

          this.dataInitial.listZonas.map((r, i) => {
            if (r.id == idZona) {
              indice = i;
            }
          });

          if (indice > 0)
            this.dataInitial.listZonas.splice(indice, 1);
          else if (indice == 0)
            this.dataInitial.listZonas.shift();

          this.zonasService.zonas.next(this.dataInitial);
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: `Zona Eliminada`, life: 1000, closable: false });
        }
      }
    });
  }

  ngOnInit() {
    this.zonasService.getZonasCreadas();
  }

  onRowSelect(event) {
    this.selectedZona = event.nombre;
    this.modalUpdateZona = true;
  }

  postZonaNueva(e) {
    if (e) {
      let zona: Zona = new Zona(0, e);

      this.zonasService.postZona(zona, (allowed) => {
        if (!allowed)
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: `Zona ${e}, No Creada`, life: 1000, closable: false });
        else {
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: `Zona ${e} Creada`, life: 1000, closable: false });
          this.modalAddZona = false;
        }
      });
    }
  }

  putZonaNueva(e: ZonaTemporal) {
    if (e) {
      let _existe = this.dataInitial.listZonas.find(x => x.nombre == e.anterior);

      if (_existe) {
        this.zonasService.putZona(e, (allowed) => {
          if (!allowed)
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: `Zona ${e}, No Actualizada`, life: 1000, closable: false });
          else {
            this.dataInitial.listZonas.map((r) => {
              if (r.nombre == e.anterior) {
                r.nombre = e.nuevo
              }
            });

            this.zonasService.zonas.next(this.dataInitial);
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: `Zona ${e} Actualizada`, life: 1000, closable: false });
            this.modalUpdateZona = false;
          }
        });
      }
    }
  }

  changeStatus(status, data: any) {
    if (!status) {
      Swal.fire({
        title: `¿Quieres eliminar esta Zona?`,
        html: `Zona: ${data.nombre}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar'
      })
        .then((willOK) => {
          if (willOK) {
            this.zonasService.deleteZona(data.id);
          }
          else {
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Accion cancelada', detail: `No se cambio el estatus`, life: 1000, closable: false });
            let encontrado: any = this.dataTable.rows.find(u => u.id == data.id);
            encontrado.estatus = !status;
          }
        });
    }
    else {
      data.estatus = true;
      status = true;
    }
  }
}
