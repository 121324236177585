import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'primeng/api';
import Swal from 'sweetalert2';
import { Password } from 'src/app/shared/models/password.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { VariablesService } from 'src/app/services/variableGL.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
  providers: [MessageService]
})
export class PerfilComponent implements OnInit {

  user: Usuario;
  tabActive: string;
  optionsMap: any;
  avatar: string[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08"];

  estatus: boolean = false;
  visibleAlertas: boolean = false;

  constructor(
    private auth: AuthService,
    private messageService: MessageService,
    private userService: UsuarioService,
    private variablesGL: VariablesService
  ) {
    this.auth.user.subscribe((data: Usuario) => {
      if (data)
        if (data.arbol != undefined) {
          this.user = data;
          this.visibleAlertas = data.rol == "Administrador" ? true : false;
        }
    });
  }

  ngOnInit() {
    this.tabActive = "TabEmpresa";
    this.estatus = this.variablesGL.sound.value;
    this.optionsMap = {
      center: { lat: 19.012853, lng: -98.193654 },
      zoom: 17
    };
  }

  changeTab(_tab: string) {
    let tabs = document.querySelector("[data-list='tabs']").childNodes;
    Object.values(tabs).map((tab: any) => {
      if (tab.id == _tab) {
        this.tabActive = _tab;
        tab.classList.add("active");
      }
      else
        tab.classList.remove("active");
    });
  }

  getDataPassword(dataPassword: Password) {
    this.auth.putPassword(dataPassword, (allowed) => {
      if (!allowed)
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acceso denegado', detail: 'Credenciales Incorrectas', life: 1000, closable: false });
      else {
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: 'Credenciales Actualizadas', life: 1000, closable: false });
      }
    });
  }

  changeAvatar(seleccion) {
    Swal.fire({
      title: "¿Quieres actualizar el Avatar?",
        html: "¡Al dar clic en OK cambiaras tu avatar!",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Actualizar',
        cancelButtonText: 'Cancelar'
    })
      .then((willOK) => {
        if (willOK) {
          if (String(this.user.avatar).includes(seleccion))
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acción cancelada', detail: 'Selecciona un Avatar diferente.', life: 2000, closable: false });
          else {
            this.userService.putUserAvatar(seleccion);
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: 'Avatar Actualizado', life: 1000, closable: false });
          }
        } else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acción cancelada', detail: 'No se cambió el Avatar.', life: 2000, closable: false });
        }
      });
  }

  changeAlarma(e) {
    let isChecked = e.checked;
    this.estatus = isChecked;
    if (this.estatus) {
      this.messageService.add({ key: 'toast', severity: 'success', summary: 'Activando', detail: 'Sonido Habilitado', life: 1000, closable: false });
      this.variablesGL.sound.next(true);
    }
    else {
      this.messageService.add({ key: 'toast', severity: 'success', summary: 'Deteniendo', detail: 'Sonido Detenido.', life: 1000, closable: false });
      this.variablesGL.sound.next(false);
    }
  }
}
