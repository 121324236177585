import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Dashboard } from 'src/app/shared/models/dashboard.model';
import { DashBoardService } from '../dashboard.service';
import { Barras } from 'src/app/shared/models/barras.model';
import { Carta } from 'src/app/shared/models/card.model';
import { Gauge } from 'src/app/shared/models/gauge.model';
import { Envio } from 'src/app/shared/models/lastEnvio.model';
import { Alerta } from 'src/app/shared/models/alerta.model';
import { AuthService } from '../auth.service';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { Grouped } from 'src/app/shared/models/grouped.model';

@Injectable({
    providedIn: 'root'
})
export class MetodosSocket {

    usuario: Usuario;
    arbol: any;

    proveedor = new BehaviorSubject<string>(null);
    alerta = new BehaviorSubject<Alerta>(null);
    showAlerta = new BehaviorSubject<Alerta>(null);
    barras: Barras[] = [];
    cartas: Carta[] = [];
    envios: Envio[] = [];
    gauges: Gauge[] = [];
    comunicanPorZona: Grouped;

    constructor(
        private dashboardService: DashBoardService,
        private authService: AuthService
    ) { }

    setProveedor() {
        this.proveedor.next("Todos");
    }

    updateDashboard_1(data: any) {
        this.barras = data.barras;
        this.cartas = data.cartas;
        this.comunicanPorZona = data.comunicanPorZona;
    }

    updateDashboard_2(data: any) {
        this.envios = data.envios;
        this.gauges = data.gauges;
        let dashboard = new Dashboard(this.cartas, this.envios, this.gauges, this.barras, this.comunicanPorZona);

        this.usuario = this.authService.user.value;

        this.arbol = JSON.parse(this.usuario.arbol);
        if (this.statusPantalla("Graficas")) {
            this.dashboardService.dashboard.next(dashboard);
            this.setProveedor();
        }
    }

    statusPantalla(pantalla: String): boolean {
        let existe: boolean = false;

        this.arbol.map(r => {
            if (r.pantalla == pantalla)
                existe = true;
        });

        return existe;
    }

    addAlerta(alerta: Alerta) {
        if (alerta)
            this.alerta.next(alerta);
    }

    showAlertas(alerta: Alerta) {
        if (alerta)
            this.showAlerta.next(alerta);
    }
}
