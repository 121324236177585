import { Component, OnInit } from '@angular/core';
import { AlertasService } from 'src/app/services/alertas.service';
import { VariablesService } from 'src/app/services/variableGL.service';
import { Alerta } from 'src/app/shared/models/alerta.model';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { AuthService } from 'src/app/services/auth.service';
import { DispositivoService } from 'src/app/services/dispositivo.service';
import { DashBoardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-activas',
  templateUrl: './activas.component.html',
  styleUrls: ['./activas.component.css'],
  providers: [MessageService]
})
export class ActivasComponent implements OnInit {

  table: any;
  usuario: Usuario;
  selectedAlertas: Alerta[] = [];
  mostrarLoading: boolean = false;

  constructor(
    private alertasService: AlertasService,
    private variablesGL: VariablesService,
    private messageService: MessageService,
    private authService: AuthService,
    private dispositivoService: DispositivoService,
    private dashbaordService: DashBoardService
  ) {
    this.alertasService.alertasActivas.subscribe(r => {
      if (r)
        if (r.length > 0) {
          this.table = this.variablesGL.getDataTable(r);
        }
    });
    this.authService.user.subscribe(r => {
      if (r)
        this.usuario = r;
    });
  }

  ngOnInit() {
    this.alertasService.getAlertasActivas();
  }

  changeStatus(status, data: Alerta) {
    if (!status) {
      Swal.fire({
        title: `¿Quieres desactivar esta Alerta  ${data.nombre}?`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Desactivar',
        cancelButtonText: 'Cancelar'
      })
        .then((willOK) => {
          if (willOK) {
            let putAlerta = {
              id: data.id,
              usuario: this.usuario.correo
            };

            this.dispositivoService.putAlarma(putAlerta, (allowed) => {
              if (!allowed)
                this.messageService.add({ key: 'toast', severity: 'error', summary: '¡Denegado!', detail: 'Error en Alerta', life: 1000, closable: false });
              else {
                this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: 'Alerta Revisada', life: 1000, closable: false });

                //#region Borro la Alerta de la tabla
                let indice;
                this.table.rows.map((r, i) => {
                  if (r.id == data.id) {
                    indice = i;
                  }
                });

                if (indice > 0)
                  this.table.rows.splice(indice, 1);
                else if (indice == 0)
                  this.table.rows.shift();
                //#endregion

                //#region Borro la Alerta del side
                let alertasTemp = this.dashbaordService.alertas.value;
                let existe = false;

                alertasTemp.map((r, i) => {
                  if (r.id == data.id) {
                    r.estatus = false;
                    existe = true;
                  }
                });

                if (existe) {
                  this.dashbaordService.alertas.next(alertasTemp);
                }

                let alarmasActivas = this.table.rows;
                if (alarmasActivas.length == 0) {
                  this.alertasService.alertasActivas.next([]);
                }
                //#endregion
              }

            });
          }
          else {
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Accion cancelada', detail: `No se cambio el estatus`, life: 1000, closable: false });
            let encontrado: Alerta = this.table.rows.find(u => u.id == data.id);
            encontrado.estatus = !status;
          }
        });
    }
    else {
      data.estatus = true;
      status = true;
    }
  }

  borrarAlertas() {
    console.log("Alertas seleccionadas: ", this.selectedAlertas);
    Swal.fire({
      title: `¿Quieres desactivar las Alertas Seleccionadas? ${this.selectedAlertas.length}`,
      icon: "info",
      showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Desactivar',
        cancelButtonText: 'Cancelar'
    })
      .then((willOK) => {
        if (willOK) {
          let alertas = [];
          this.selectedAlertas.forEach(item => {
            alertas.push(item.id.toString());
          });
          let arrayDataBorrar = {
            alarmasBorrar: alertas,
            usuario: this.usuario.correo
          }

          console.log("ALERTAS ID STRING: ", arrayDataBorrar);
          this.mostrarLoading = true;
          this.dispositivoService.putAlarmaMasivasBorrar(arrayDataBorrar, (allowed) => {
            if (!allowed) {
              this.mostrarLoading = false;
              this.messageService.add({ key: 'toast', severity: 'error', summary: '¡Denegado!', detail: 'Error en Alerta', life: 1000, closable: false });
            } else {
              this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: 'Alerta Revisada', life: 1000, closable: false });
              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          });
        }
        else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Accion cancelada', detail: `No se cambio el estatus`, life: 1000, closable: false });
          this.selectedAlertas = [];
        }
      });


  }
}
