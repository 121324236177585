import { Component, Input, OnChanges } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',
  styleUrls: ['./registros.component.css'],
  providers: [MessageService]
})
export class RegistrosComponent implements OnChanges {

  @Input() dataTable: any;
  table: any;
  temTable: any;
  activarRango = false;
  registros: any;

  constructor(
    private variablesGL: VariablesService,
    private messageService: MessageService,
    public datepipe: DatePipe
  ) { }

  ngOnChanges() {
    if (this.dataTable) {
      this.table = this.variablesGL.getDataTable(this.dataTable.datos);
      this.temTable = this.variablesGL.getDataTable(this.dataTable.datos);
    }
  }

  findDateInicial() {
    let fechaInicial: any = document.getElementById("date-inicial");
    let _fecha = String(this.datepipe.transform(fechaInicial.value, 'shortDate'));
    let datosRegistros: any[] = [];
    if (fechaInicial.value) {
      Object.values(this.temTable.rows).map((v: any) => {
        if (String(v.fecha).includes(_fecha)) {
          datosRegistros.push({
            valor: v.valor,
            fecha: v.fecha
          });
        }
      });
      this.table.rows = datosRegistros;
      if (datosRegistros.length > 0)
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Cargando', detail: `¡Tabla filtrada!`, life: 1000, closable: false });
      else
        this.messageService.add({ key: 'toast', severity: 'error', summary: '¡Denegado', detail: `¡No ha comunicado el dia: ${fechaInicial.value} !`, life: 1000, closable: false });
    }
  }

  findDateFinal() {
    let fechaInicial: any = document.getElementById("date-inicial");
    let fechaFinal: any = document.getElementById("date-final");

    let temInicla = new Date(`${fechaInicial.value}T00:00:00`);
    let temFinal = new Date(`${fechaFinal.value}T23:59:00`);

    let datosRegistros: any[] = [];

    if (fechaInicial.value && fechaFinal.value) {
      Object.values(this.temTable.rows).map((v: any) => {

        let _ordenar = String(v.fecha).split(' ')[0].split('/');

        let tempfecha = new Date(`20${_ordenar[2]}/${_ordenar[1]}/${_ordenar[0]}`);

        if (tempfecha >= temInicla && tempfecha <= temFinal) {
          datosRegistros.push({
            valor: v.valor,
            fecha: v.fecha
          });

        }
      });
      this.table.rows = datosRegistros;
      if (datosRegistros.length > 0)
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Cargando', detail: `¡Tabla filtrada!`, life: 1000, closable: false });
      else
        this.messageService.add({ key: 'toast', severity: 'error', summary: '¡Denegado', detail: `¡No ha comunicado el rango: ${fechaInicial.value} a ${fechaFinal.value} !`, life: 1000, closable: false });
    }
  }

  removeFiltros() {
    this.messageService.add({ key: 'toast', severity: 'success', summary: '¡Removiendo Filtros!', detail: `¡La tabla restablecio sus valores!`, life: 1000, closable: false });

    this.table.rows = this.temTable.rows;

    this.activarRango = false;
  }

}
