import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ConfigService } from 'src/config/config.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private aService: AuthService,
    private router: Router,
    private config: ConfigService
  ) { 
    this.config.setLocal();
    this.router.events
      .pipe(filter(d => d instanceof NavigationEnd))
      .subscribe((d: any) => {
        if (d.url == '/') {
          if (this.aService.user.value == null)
            this.aService.getUser();
        }
      });
  }
}
