import { Component, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PhonePipe } from 'src/app/shared/pipes/telefono.pipe';
import { VariablesService } from 'src/app/services/variableGL.service';
import { Usuario } from 'src/app/shared/models/usuario.model';

@Component({
  selector: 'app-fadd-user',
  templateUrl: './fadd-user.component.html',
  styleUrls: ['./fadd-user.component.css'],
  providers: [PhonePipe]
})

export class FaddUserComponent implements OnInit {

  @ViewChild('myForm', { static: false, }) formValues;
  form: FormGroup;
  submitted = false;
  tel = '';

  @Output() dataUser = new EventEmitter<Usuario>();

  constructor(
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private phone: PhonePipe,
    private variablesGL: VariablesService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      nombre: new FormControl('', [Validators.required, Validators.minLength(4)]),
      peterno: new FormControl('', [Validators.required, Validators.minLength(3)]),
      materno: new FormControl('', [Validators.required, Validators.minLength(3)]),
      correo: new FormControl('', [Validators.required, Validators.email]),
      telefono: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
    });
  }

  get f() { return JSON.stringify(this.form.controls); }

  enviarDatos() {
    this.submitted = true;

    if (this.form.invalid) return;
    else {

      this.variablesGL.stepAddUser.next(1);
      this.dataUser.emit(new Usuario(null, this.form.value.nombre, this.form.value.peterno + ' ' + this.form.value.materno, this.form.value.correo, this.form.value.telefono, true, null, null, this.form.value.contrasena, null, null));

      this.submitted = false;
    }
  }

  onPhoneChange(value) {
    if (value) {
      let temp = value.replace(/-/g, '');
      this.form.controls.telefono.patchValue(this.phone.transform(temp, ''));
      this.cdRef.detectChanges();
    }
  }
}
