import { Component, OnInit } from '@angular/core';

import * as ApexCharts from 'apexcharts';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.css']
})
export class DonutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'];

    var options = {
      title: {
        text: 'Dispositivos por Zonas',
        floating: true,
        align: 'center',
        style: {
          color: '#444'
        }
      },
      chart: {
        type: 'donut',
      },
      series: [44, 55, 41, 17, 15],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    var chart = new ApexCharts(document.querySelector("#chartDonut"), options);

    chart.render();
  }

}
