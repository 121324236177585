import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from 'src/app/shared/animations/fade';
import { VariablesService } from 'src/app/services/variableGL.service';
import { AuthService } from 'src/app/services/auth.service';
import { Usuario } from '../../models/usuario.model';
import { DashBoardService } from 'src/app/services/dashboard.service';
import { MetodosSocket } from 'src/app/services/WebSocket/metodosSocket.service';
import { AlertasService } from 'src/app/services/alertas.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  animations: [fadeAnimation]
})
export class NavBarComponent implements OnInit {

  sideNormal = true;
  avatar: string;
  visibleAlertas: boolean = false;
  visibleNotify: boolean = false;

  constructor(
    private variablesGL: VariablesService,
    private auth: AuthService,
    private metodosSocket: MetodosSocket,
    private alertasService: AlertasService
  ) { 
    this.auth.user.subscribe((data:Usuario) => {
      if (data) {
        if (data.arbol != undefined) {
          this.avatar = this.auth.user.value.avatar;
          this.visibleAlertas = data.rol == "Administrador" ? true : false;
        }
      }
    });

    this.metodosSocket.alerta.subscribe(r => {
      if (r)
        this.visibleNotify = true;
    });

    this.alertasService.alertasActivas.subscribe(r => {
      if (r == [] || r == null || r.length == 0)
        this.visibleNotify = false;
      else
        this.visibleNotify = true;
    });
  }

  ngOnInit() {}

  changeSideUser() {
    this.variablesGL.showSideUser.next(true);
    this.variablesGL.showSideSMS.next(false);
    this.variablesGL.showSideAlertas.next(false);
  }
  changeSideSMS() {
    this.variablesGL.showSideUser.next(false);
    this.variablesGL.showSideSMS.next(true);
    this.variablesGL.showSideAlertas.next(false);
  }
  changeSideAlertas() {
    this.variablesGL.showSideUser.next(false);
    this.variablesGL.showSideSMS.next(false);
    this.variablesGL.showSideAlertas.next(true);
  }

}
