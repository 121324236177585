import { Carta } from "./card.model";
import { Envio } from "./lastEnvio.model";
import { Barras } from "./barras.model";
import { Gauge } from "./gauge.model";
import { Grouped } from "./grouped.model";

export class Dashboard {
    constructor(
        public cartas: Carta[],
        public envios: Envio[],
        public gauges: Gauge[],
        public barras: Barras[],
        public comunicanPorZona: Grouped,
    ){}
}