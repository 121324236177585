import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { Usuario } from '../../models/usuario.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  items: MenuItem[];
  user: Usuario;
  arbol: any;

  constructor(
    private auth: AuthService
  ) {
    this.auth.user.subscribe((data: Usuario) => {
      if (data) {
        if (data.arbol != undefined) {
          this.user = data;
          this.arbol = JSON.parse(this.user.arbol);
          this.items = [
            {
              label: 'Principal',
              icon: 'fas fa-home',
              visible: (this.user.rol == "Administrador" || this.user.rol == "Jefe" || this.user.rol == "Encargado de Zona" ? true : false),
              items: [
                { label: 'Gráficas', icon: 'fas fa-chart-bar', routerLink: "/", visible: this.statusPantalla("Graficas") },
                { label: 'Mapa', icon: 'fas fa-map', routerLink: "/mapa", visible: this.statusPantalla("Mapa") }
              ]
            },
            {
              label: 'Alarmas',
              icon: 'fas fa-bell',
              visible: (this.user.rol == "Administrador" ? true : false),
              items: [
                { label: 'Activas', routerLink: "/notifi-activas" },
                { label: 'Histórico', routerLink: "/notifi-historico" },
                { label: 'Alta Masiva', routerLink: "/cargar" }
              ]
            },
            {
              label: 'Configuración',
              icon: 'fas fa-cogs',
              visible: (this.user.rol == "Administrador" || this.user.rol == "Jefe" || this.user.rol == "Encargado de Zona" ? true : false),
              items: [
                { label: 'Usuarios', icon: 'fas fa-users', routerLink: "/usuarios", visible: this.statusPantalla("Usuarios") },
                { label: 'Zonas', icon: 'fas fa-map-marker-alt', routerLink: "/zonas" },
                { label: 'Perfil', icon: 'fas fa-users', routerLink: "/perfil" }
              ]
            },
            {
              label: 'Reportes',
              icon: 'fas fa-clipboard-list',
              visible: (this.user.rol == "Administrador" ? true : false),
              items: [
                { label: 'Dispositivos', icon: 'fas fa-hdd', routerLink: "/reporte/dispositivos" }
              ]
            }
          ];
        }
      }
    });
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Cargando',
        icon: 'fas fa-home',
      }
    ];
  }
  statusPantalla(pantalla: String): boolean {
    let existe: boolean = false;

    this.arbol.map(r => {
      if (r.pantalla == pantalla)
        existe = true;
    });

    return existe;
  }
  activeItem($event) {
    let seleccionado = document.getElementById($event);
    if (seleccionado)
      seleccionado.classList.toggle("addclass");
  }
}
