import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Dispositivo } from 'src/app/shared/models/dispositivo.model';
import { MessageService } from 'primeng/api';
import { DispositivoService } from 'src/app/services/dispositivo.service';
import { Registro } from 'src/app/shared/models/registro.model';
import { CalendarEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { VariablesService } from 'src/app/services/variableGL.service';
import Swal from 'sweetalert2';
import { ZonaTemporal } from 'src/app/shared/models/zonaTemp.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mdescripcion',
  templateUrl: './mdescripcion.component.html',
  styleUrls: ['./mdescripcion.component.css'],
  providers: [MessageService]
})
export class MdescripcionComponent implements OnChanges, OnInit, OnDestroy {

  @Input() dispositivo: Dispositivo;
  @Output() dispositivoDelete = new EventEmitter<Dispositivo>();
  data: any;
  lastRecords: any[] = [];

  visibleHistorico = false;
  tabla: any;

  registros: any[] = [];

  error: boolean = false;
  spiner: boolean = false;

  modalAlarmas: boolean = false;
  modalCalendario: boolean = false;
  modalVolumen: boolean = false;
  modalZonaExtra: boolean = false;
  modalFiltro: boolean = false;
  dataTable: any;

  dataAlarmaCanal: any;

  view: string = 'month';
  locale: string = 'es';
  viewDate: Date = new Date();
  events: CalendarEvent[];
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  comunico: any[] = [];

  zonas: _Zona[];
  selectedZona: _Zona;
  selectedZonaTemp: _Zona;
  mostrar: boolean;

  constructor(
    private messageService: MessageService,
    private dispositivoServicio: DispositivoService,
    private variablesGL: VariablesService,
    private datepipe: DatePipe
  ) {

    this.dispositivoServicio.dispositivo.subscribe(d => {
      if (d) {
        this.data = d;
        let arreglo: any[] = [];
        let arriba: any[] = [];
        let abajo: any[] = [];
        let caudal: any[] = [];
        this.lastRecords = [];
        this.registros = [];

        var _registros: any[] = [];

        switch (d.tipo) {
          case 'Control':
            this.tabla = {
              tipo: d.tipo,
              abajo: d.presionAbajo,
              caudal: d.caudal,
              arriba: d.presionArriba
            };

            _registros.push({
              tipo: "Presión Abajo",
              datos: this.tabla.abajo.registros
            });

            _registros.push({
              tipo: "Caudal",
              datos: this.tabla.caudal.registros
            });

            _registros.push({
              tipo: "Presión Arriba",
              datos: this.tabla.arriba.registros
            });

            this.registros = _registros;
            break;
          case 'Multilog-2':
            if (d.presion && d.caudal) {
              this.tabla = {
                tipo: d.tipo,
                presion: d.presion,
                caudal: d.caudal
              };
            }
            else if (d.presionAbajo && d.caudal) {
              this.tabla = {
                tipo: d.tipo,
                presion: d.presionAbajo,
                caudal: d.caudal
              };
            }
            else if (d.presionArriba && d.caudal) {
              this.tabla = {
                tipo: d.tipo,
                presion: d.presionArriba,
                caudal: d.caudal
              };
            }

            _registros.push({
              tipo: "Presión",
              datos: this.tabla.presion.registros
            });

            _registros.push({
              tipo: "Caudal",
              datos: this.tabla.caudal.registros
            });

            this.registros = _registros;
            break;
          case 'Multilog-1':
            this.tabla = {
              tipo: d.tipo,
              abajo: d.presionAbajo,
              arriba: d.presionArriba
            };

            _registros.push({
              tipo: "Presión Abajo",
              datos: this.tabla.abajo.registros
            });

            _registros.push({
              tipo: "Presión Arriba",
              datos: this.tabla.arriba.registros
            });

            this.registros = _registros;
            break;
          case 'Logger-2':
            this.tabla = {
              tipo: d.tipo,
              canal: "Caudal",
              datos: d.caudal
            };
            break;
          default:
            this.tabla = {
              tipo: d.tipo,
              canal: "presion",
              datos: d.presion
            };
            break;
        }

        if (this.tabla.tipo == "Logger") {
          setTimeout(() => {
            let registrosDescendente = (<any[]> d.presion.reverse());

            registrosDescendente.splice(0, 5).map(r => {
              arreglo.push({
                tipo: "presion",
                valor: r.valor,
                fecha: r.fecha
              });
            });

            this.lastRecords = arreglo;
          }, 1000);
        }
        else if (this.tabla.tipo == "Logger-2") {
          setTimeout(() => {
            let registrosDescendente = (<any[]> d.caudal.reverse());

            registrosDescendente.splice(0, 5).map(r => {
              arreglo.push({
                tipo: "caudal",
                valor: r.valor,
                fecha: r.fecha
              });
            });

            this.lastRecords = arreglo;
          }, 1000);
        }
        else if (this.tabla.tipo == "Multilog-1") {
          setTimeout(() => {
            let abajoDescendente = (<any[]> d.presionAbajo.registros.reverse());
            let arribaDescendente = (<any[]> d.presionArriba.registros.reverse());

            abajoDescendente.splice(0, 5).map(r => {
              abajo.push({
                tipo: "abajo",
                valor: r.valor,
                fecha: r.fecha,
                unidad: "kg/cm2"
              });
            });

            arribaDescendente.splice(0, 5).map(r => {
              arriba.push({
                tipo: "arriba",
                valor: r.valor,
                fecha: r.fecha,
                unidad: "kg/cm2"
              });
            });


            this.lastRecords.push(arriba[0]);
            this.lastRecords.push(abajo[0]);
            this.lastRecords.push(arriba[1]);
            this.lastRecords.push(abajo[1]);
            this.lastRecords.push(arriba[2]);
          }, 1000);
        }
        else if (this.tabla.tipo == "Multilog-2") {
          setTimeout(() => {
            let presionDescendente = (<any[]> d.presion.registros.reverse());
            let caudalDescendente = (<any[]> d.caudal.registros.reverse());

            presionDescendente.splice(0, 5).map(r => {
              arreglo.push({
                tipo: "presion",
                valor: r.valor,
                fecha: r.fecha,
                unidad: "kg/cm2"
              });
            });

            caudalDescendente.splice(0, 5).map(r => {
              caudal.push({
                tipo: "caudal",
                valor: r.valor,
                fecha: r.fecha,
                unidad: "l/s"
              });
            });

            this.lastRecords.push(caudal[0]);
            this.lastRecords.push(arreglo[0]);
            this.lastRecords.push(caudal[1]);
            this.lastRecords.push(arreglo[1]);
            this.lastRecords.push(caudal[2]);
          }, 1000);
        }
        else if (this.tabla.tipo == "Control") {
          setTimeout(() => {
            let abajoDescendente = (<any[]> d.presionAbajo.registros.reverse());
            let caudalDescendente = (<any[]> d.caudal.registros.reverse());
            let arribaDescendente = (<any[]> d.presionArriba.registros.reverse());

            abajoDescendente.splice(0, 5).map(r => {
              abajo.push({
                tipo: "abajo",
                valor: r.valor,
                fecha: r.fecha,
                unidad: "kg/cm2"
              });
            });

            arribaDescendente.splice(0, 5).map(r => {
              arriba.push({
                tipo: "arriba",
                valor: r.valor,
                fecha: r.fecha,
                unidad: "kg/cm2"
              });
            });

            caudalDescendente.splice(0, 5).map(r => {
              caudal.push({
                tipo: "caudal",
                valor: r.valor,
                fecha: r.fecha,
                unidad: "l/s"
              });
            });

            this.lastRecords.push(caudal[0]);
            this.lastRecords.push(arriba[0]);
            this.lastRecords.push(abajo[0]);
            this.lastRecords.push(caudal[1]);
            this.lastRecords.push(arriba[1]);
          }, 1000);
        }
      }
    });
    this.dispositivoServicio.alarmas.subscribe(r => {
      if (r) {
        if (r.mensaje != "No existe") {
          this.dataAlarmaCanal = r;
        }
      }
    });

    this.dispositivoServicio.zonasExtra.subscribe(d => {
      if (d) {
        let _zonas: _Zona[] = [];
        d.listZonas.map(r => {
          _zonas.push({
            name: r.nombre,
            code: r.id
          });
        });
        this.zonas = _zonas;

        if (d.seleccionada > 0) {
          let zonaExtra = _zonas.find(x => x.code == d.seleccionada);
          this.selectedZonaTemp = zonaExtra;
          this.selectedZona = zonaExtra;
        }
      }
    });
    this.mostrar = this.variablesGL.existe.value;
  }

  ngOnInit() {
    this.mostrar = this.variablesGL.existe.value;
  }

  ngOnChanges(): void {
    var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);


    if (this.dispositivo) {
      this.lastRecords = [];
      this.modalAlarmas = false;
      this.visibleHistorico = false;
      this.modalCalendario = false;
      this.messageService.add({ key: 'toast', severity: 'success', summary: 'Cargando', detail: `Espera unos segundos`, life: 1000, closable: false });
    }
  }

  ngOnDestroy() {
    this.mostrar = false;
  }

  showHistorico(estado: boolean) {
    this.visibleHistorico = estado;
    this.messageService.add({ key: 'toast', severity: 'success', summary: 'Cargando Tabla', detail: `Espera unos segundos`, life: 1000, closable: false });

    setTimeout(() => {
      let div = document.getElementById("TablaHistorico");
      div.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 500);
  }

  showCalendar(estado: boolean) {
    let _fechas: any[] = [];
    if (this.tabla.tipo == "Logger") {
      this.tabla.datos.map((d: Registro) => {
        let _date = String(d.fecha).slice(0, 10);
        let encontrado = _fechas.find(x => x.start == _date);
        if (!encontrado) {
          _fechas.push({
            title: "Comunico",
            start: _date,
            color: { primary: '#69F0AE', secondary: '#40C4FF' }
          });
        }
      });
    }
    else if (this.tabla.tipo == "Multilog-2") {
      this.tabla.caudal.registros.map((d: Registro) => {
        let _date = String(d.fecha).slice(0, 10);
        let encontrado = _fechas.find(x => x.start == _date);
        if (!encontrado) {
          _fechas.push({
            title: "Comunico",
            start: _date,
            color: { primary: '#69F0AE', secondary: '#40C4FF' }
          });
        }
      });
    }
    else if (this.tabla.tipo == "Multilog-1" || this.tabla.tipo == "Control") {
      this.tabla.abajo.registros.map((d: Registro) => {
        let _date = String(d.fecha).slice(0, 10);
        let encontrado = _fechas.find(x => x.start == _date);
        if (!encontrado) {
          _fechas.push({
            title: "Comunico",
            start: _date,
            color: { primary: '#69F0AE', secondary: '#40C4FF' }
          });
        }
      });
    }

    _fechas.reverse();

    _fechas.map(d => {
      d.start = new Date(`${d.start}T00:00:00`)
    });

    this.events = _fechas;
    this.modalCalendario = estado;
  }

  showAlarmas(estado: boolean) {
    this.modalAlarmas = estado;
    this.dispositivoServicio.alarmas.next(null);
    this.dispositivoServicio.getAlarmas(this.dispositivo.id);
  }

  showVolumen(estado: boolean) {
    let _fechas: any[] = [];
    this.tabla.caudal.registros.map((d: Registro) => {
      let _date = String(d.fecha).slice(0, 10);
      let encontrado = _fechas.find(x => x == _date);
      if (!encontrado) {
        _fechas.push(_date);
      }
    });

    let _datosCaudal: any[] = [];

    _fechas.reverse().map(d => {

      if (d == "2019-06-01") {
      }

      let _arregloDatos = this.tabla.caudal.registros.filter(x =>
        String(x.fecha).includes(d)
      );

      _arregloDatos.reverse();

      let suma = 0;

      if (_arregloDatos.length == 96) {
        for (let _i = 1; _i < _arregloDatos.length; _i++) {
          let _casilla_1 = _arregloDatos[_i - 1].valor * 900;
          let _casilla_2 = _arregloDatos[_i].valor * 900;
          let res = ((_casilla_1 + _casilla_2) / 2) / 1000;
          suma = suma + res;
        }

        _datosCaudal.push({
          dia: d,
          total: suma
        });
      }
      else if (_arregloDatos.length == 144) {
        for (let _i = 1; _i < _arregloDatos.length; _i++) {
          let _casilla_1 = _arregloDatos[_i - 1].valor * 600;
          let _casilla_2 = _arregloDatos[_i].valor * 600;
          let res = ((_casilla_1 + _casilla_2) / 2) / 1000;
          suma = suma + res;
        }

        _datosCaudal.push({
          dia: d,
          total: suma
        });
      }
      else if (_arregloDatos.length == 288) {
        for (let _i = 1; _i < _arregloDatos.length; _i++) {
          let _casilla_1 = _arregloDatos[_i - 1].valor * 300;
          let _casilla_2 = _arregloDatos[_i].valor * 300;
          let res = ((_casilla_1 + _casilla_2) / 2) / 1000;
          suma = suma + res;
        }

        _datosCaudal.push({
          dia: d,
          total: suma
        });
      }
    });
    _datosCaudal.reverse();
    this.dataTable = this.variablesGL.getDataTable(_datosCaudal);
    this.modalVolumen = estado;
  }

  showZonaExtra(estado: boolean) {
    this.modalZonaExtra = estado;
    this.selectedZona = null;
    this.selectedZonaTemp = null;
    this.dispositivoServicio.getZonaExtra(this.dispositivo.id);
  }

  showFiltroFechas(estado: boolean) {
    this.modalFiltro = estado;
  }

  postAlarma($event) {
    let data = {
      dispositivo: this.dispositivo,
      canal: $event.canal,
      datos: $event.datos
    };

    this.dispositivoServicio.postAlarma(data, (allowed) => {
      if (!allowed)
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: `No se creo Alarma`, life: 1000, closable: false });
      else
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: `Alarma Creada`, life: 1000, closable: false });
    });

  }

  changeZonaExtra(event) {
    if (this.selectedZona != this.selectedZonaTemp) {
      Swal.fire({
        title: "¿Quieres agregar a esta Zona Extra?",
        html: `Zona Extra: ${this.selectedZona.name}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '(this.selectedZonaTemp == null) ? "Agregar" : "Cambiar"',
        cancelButtonText: 'Cancelar'
      })
        .then((willOK) => {
          if (willOK) {
            let _putZona: any = {
              id: this.dispositivo.id,
              zona: new ZonaTemporal((this.selectedZonaTemp == null) ? "" : this.selectedZonaTemp.name, this.selectedZona.name)
            }

            this.dispositivoServicio.putZonaExtra(_putZona, (allowed) => {
              if (!allowed)
                this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'No se agrego a Zona', life: 2000, closable: false });
              else {
                this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: (this.selectedZonaTemp == null) ? `Agregando a Zona Extra` : "Cambiando de Zona", life: 2000, closable: false });
                this.selectedZonaTemp = this.selectedZona;
              }
            });
          } else {
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acción cancelada', detail: `No se agrego a Zona`, life: 2000, closable: false });
            this.selectedZona = this.selectedZonaTemp;
          }
        });
    }
    else
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acción cancelada', detail: `Selecciona una Zona diferente`, life: 2000, closable: false });
  }

  removerZonaExtra() {
    Swal.fire({
        title: "¿Quieres retirar este logger de esta Zona Extra?",
        html: `Zona Extra: ${this.selectedZona.name}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Retirar',
        cancelButtonText: 'Cancelar'
    })
      .then((willOK) => {
        if (willOK) {

          this.dispositivoServicio.deleteZonaExtra(this.dispositivo.id, (allowed) => {
            if (!allowed)
              this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'No se retiro de Zona Extra', life: 2000, closable: false });
            else {
              this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: "Retirando de Zona Extra", life: 2000, closable: false });
              this.selectedZonaTemp = null;
              this.selectedZona = null;
            }
          });
        } else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acción cancelada', detail: `No se retiro de Zona Extra`, life: 2000, closable: false });
        }
      });
  }

  deshabilitarDispositivo() {
    Swal.fire({
        title: this.dispositivo.habilitado ? `¿Quieres Deshabilitar éste Dispositivo?` : `¿Quieres Habilitar éste Dispositivo?`,
        html: `Logger: ${this.dispositivo.nombre}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: this.dispositivo.habilitado ? "Deshabilitar" : "Habilitar",
        cancelButtonText: 'Cancelar'

    })
      .then((willOK) => {
        if (willOK) {
          this.dispositivoDelete.emit(this.dispositivo);
        }
        else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Accion cancelada', detail: `No se cambio el estatus`, life: 2000, closable: false });
        }
      });
  }

  sendAplicar() {
    let fechaInicial: any = document.getElementById("fi");
    let fechaFinal: any = document.getElementById("ff");
    this.spiner = true;

    if (fechaInicial.value && fechaFinal.value) {
      this.error = false;

      let tempInicial = this.datepipe.transform(fechaInicial.value, "yyyy-MM-dd");
      let tempFinal = this.datepipe.transform(fechaFinal.value, "yyyy-MM-dd");


      setTimeout(() => {
        this.spiner = false;
        this.dispositivoServicio.getDispositivo(this.dispositivo.id, tempInicial, tempFinal);
        fechaInicial.value = "";
        fechaFinal.value = "";
      }, 1000);
      this.modalFiltro = false;
    } else {
      this.error = true;
      this.spiner = false;
    }
  }

}

interface _Zona {
  name: string;
  code: string;
}
