import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Rol } from '../shared/models/rol.model';
import { URL_ServAccount } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class RolService {

    roles = new BehaviorSubject<Rol[]>(null);

    constructor(
        private http: HttpClientService,
        private router: Router
    ) { }

    getRoles() {
        this.http.get(`${URL_ServAccount}/user`)
            .subscribe((res: any) => {
                this.roles.next(res);
            }, (err) => {
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

}
