import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { VariablesService } from './variableGL.service';
import { Dispositivo } from '../shared/models/dispositivo.model';
import { DateTime } from '@syncfusion/ej2-angular-charts';
import { URL_ServDevice } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class DispositivoService {

  dispositivo = new BehaviorSubject<any>(null);
  alarmas = new BehaviorSubject<any>(null);
  zonasExtra = new BehaviorSubject<any>(null);
  dataExcel = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClientService,
    private variablesGL: VariablesService,
    private router: Router
  ) { }

  getDispositivo(idDispositivo: number, rangoInicio: string, rangoFin: string) {
    this.variablesGL.showLoading.next(true);
    this.dispositivo.next(null);
    this.http.get(`${URL_ServDevice}/device?id=${idDispositivo}&&rangoInicio=${rangoInicio}&&rangoFin=${rangoFin}`)
      .subscribe((res: any) => {
        if (res)
          if (res.mensaje == "Exito") {

            if (res.tipo == "Logger") {
              let canal = {
                tipo: res.tipo,
                presion: res.presion
              };

              this.dispositivo.next(canal);
              this.variablesGL.showLoading.next(false);
            }
            if (res.tipo == "Logger-2") {
              let canal = {
                tipo: res.tipo,
                caudal: res.caudal
              };

              this.dispositivo.next(canal);
              this.variablesGL.showLoading.next(false);
            }
            else if (res.tipo == "Multilog-1") {
              let presionAbajo = {
                registros: res.presionAbajo
              };

              let presionArriba = {
                registros: res.presionArriba
              };

              let canales = {
                tipo: res.tipo,
                presionAbajo,
                presionArriba
              }

              this.dispositivo.next(canales);
              this.variablesGL.showLoading.next(false);
            }
            else if (res.tipo == "Multilog-2") {
              let presion = {
                registros: res.presion
              };

              let presionAbajo = {
                registros: res.presionAbajo
              };

              let presionArriba = {
                registros: res.presionArriba
              };

              let caudal = {
                registros: res.caudal
              };

              if (presion && caudal) {

                let canales = {
                  tipo: res.tipo,
                  presion,
                  caudal
                }

                this.dispositivo.next(canales);
                this.variablesGL.showLoading.next(false);
              }
              else if (presionAbajo && caudal) {

                let canales = {
                  tipo: res.tipo,
                  presionAbajo,
                  caudal
                }

                this.dispositivo.next(canales);
                this.variablesGL.showLoading.next(false);
              }
              else if (presionArriba && caudal) {

                let canales = {
                  tipo: res.tipo,
                  presionArriba,
                  caudal
                }

                this.dispositivo.next(canales);
                this.variablesGL.showLoading.next(false);
              }
            }
            else if (res.tipo == "Control") {
              let presionAbajo = {
                registros: res.presionAbajo
              };

              let presionArriba = {
                registros: res.presionArriba
              };

              let caudal = {
                registros: res.caudal
              };

              let canales = {
                tipo: res.tipo,
                presionAbajo,
                presionArriba,
                caudal
              }
              this.dispositivo.next(canales);
              this.variablesGL.showLoading.next(false);
            }
          }
      }, (err) => {
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  putHabilitadoDispositivo(dispositivo: Dispositivo, call) {
    this.http.post(`${URL_ServDevice}/activedevice`, JSON.stringify(dispositivo))
      .subscribe((res: any) => {
        if (res)
          call(res && res.mensaje == "Exito" ? true : false)
      }, (err) => {
        console.error(err);
        if (err.status == 401) {
          console.log("error en el service");

          // this.router.navigate(['/'], { replaceUrl: true });
          // 
          // location.reload();
        }
      });
  }

  getAlarmas(idDispositivo: number) {
    this.variablesGL.showLoading.next(true);
    this.http.get(`${URL_ServDevice}/alarms?id=${idDispositivo}`)
      .subscribe((res: any) => {
        if (res) {
          this.alarmas.next(res)
          this.variablesGL.showLoading.next(null);
        }
      }, (err) => {
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  postAlarma(data: any, call) {
    this.http.post(`${URL_ServDevice}/newalarm`, JSON.stringify(data))
      .subscribe((res: any) => {
        if (res)
          call(res ? true : false)
      }, (err) => {
        console.error(err);
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  putAlarma(data: any, call) {
    this.http.post(`${URL_ServDevice}/newalert`, JSON.stringify(data))
      .subscribe((res: any) => {
        if (res)
          call(res && res.mensaje == "Exito" ? true : false)
      }, (err) => {
        console.error(err);
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  getZonaExtra(idDispositivo: number) {
    this.variablesGL.showLoading.next(true);

    this.http.get(`${URL_ServDevice}/extzone?id=${idDispositivo}`)
      .subscribe((res: any) => {
        if (res) {
          this.zonasExtra.next(res)
          this.variablesGL.showLoading.next(false);
        }
      }, (err) => {
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  putZonaExtra(data: any, call) {
    this.http.post(`${URL_ServDevice}/newextzone`, JSON.stringify(data))
      .subscribe((res: any) => {
        if (res)
          call(res ? true : false)
      }, (err) => {
        console.error(err);
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  deleteZonaExtra(idDispositivo: number, call) {
    this.http.get(`${URL_ServDevice}/deleteextzone?id=${idDispositivo}`)
      .subscribe((res: any) => {
        if (res)
          call(res ? true : false)
      }, (err) => {
        console.error(err);
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }

  putAlarmaMasivas(data: string) {
    this.http.post(`${URL_ServDevice}/newmasivealarms`, { data: data })
      .subscribe((res: any) => {
        if (res)
          this.dataExcel.next(res);
      }, (err) => {
        console.error(err);
      });
  }

  putAlarmaMasivasBorrar(data: any, call) {
    console.log("Service data : ", data);

    this.http.post(`${URL_ServDevice}/masivealarms`, { data: data })
      .subscribe((res: any) => {
        if (res)
          call(res && res.mensaje == "Exito" ? true : false)
      }, (err) => {
        console.error(err);
        if (err.status == 401) {
          this.router.navigate(['/'], { replaceUrl: true });

          location.reload();
        }
      });
  }
}
