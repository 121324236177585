// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // DEVELOP
  // URL_Socket: "ws://10.6.95.43:177/UberWach/?2",
  // URL_Servicios: "http://10.6.95.45:91/",
  // URL_Mailer: "http://10.6.95.45:190/",
  // QA
  //  URL_Socket: "ws://10.6.95.43:177/UberWach/?2",
  //  URL_Servicios: "http://pruebas-apiscada.hi2o.mx/",
  //  URL_Mailer: "http://pruebas-apimailing.hi2o.mx/",
  // PRODUCTION
   URL_Socket: "ws://10.6.95.43:177/UberWach/?2",
   URL_Servicios: "https://apiscada.aguapuebla.mx/",
   URL_Mailer: "https://apimailing.aguapuebla.mx/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
