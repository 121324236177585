import { Component, OnInit, Input } from '@angular/core';
import { Carta } from 'src/app/shared/models/card.model';
import { VariablesService } from 'src/app/services/variableGL.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() data: Carta;
  carta: Carta;
  mostrar: boolean;

  constructor(
    private variablesGL: VariablesService
  ) { }

  ngOnInit() {
    if (this.data) {
      this.carta = this.data;
      this.mostrar = this.variablesGL.existe.value;
    }
  }
}
