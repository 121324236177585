import { Component, OnInit, Input } from '@angular/core';
import { Gauge } from 'src/app/shared/models/gauge.model';

import * as ApexCharts from 'apexcharts';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.css']
})
export class GaugeComponent implements OnInit {

  @Input() dataGauge: Gauge;

  constructor() { }

  ngOnInit() {
    if (this.dataGauge) {

      var options = {
        chart: {
          height: 200,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: `70%`,
            }
          },
        },
        fill: {
          colors: [function ({ value }) {
            if (value < 55) {
              return '#FF6E40'
            } else if (value >= 55 && value < 80) {
              return '#FFFF00'
            } else {
              return '#B2FF59'
            }
          }],
        },
        series: [this.dataGauge.valor],
        labels: [this.dataGauge.proveedor]
      }

      setTimeout(() => {
        var chart = new ApexCharts(document.querySelector(`#${this.dataGauge.proveedor}Gauge`), options);
        chart.render();
      }, 100);
    }
  }
}
