import { Component, OnInit, Input } from '@angular/core';
import { Envio } from 'src/app/shared/models/lastEnvio.model';

@Component({
  selector: 'app-envio',
  templateUrl: './envio.component.html',
  styleUrls: ['./envio.component.css']
})
export class EnvioComponent implements OnInit {

  @Input() data: Envio;
  envio: Envio;
  
  constructor() { }

  ngOnInit() {
    if (this.data) {
      this.envio = this.data;    
    }
  }

}
