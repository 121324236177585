import { Component, Input, OnChanges } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';

declare const Highcharts;

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.css']
})
export class ControlComponent implements OnChanges {

  @Input() dataControl: any;
  stockControl: any;

  constructor(
    private variablesGL: VariablesService
  ) { }

  ngOnChanges() {
    if (this.dataControl) {

      let abajo = this.dataControl.abajo.registros.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });

      let caudal = this.dataControl.caudal.registros.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });

      let arriba = this.dataControl.arriba.registros.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });
      
      this.stockControl = Highcharts.stockChart('line-Area-Control', this.setControl())
      this.updateStockControl(caudal, arriba, abajo);

    }
  }

  setControl() {
    return {
      chart: this.variablesGL.zoomChartStock,
      legend: this.variablesGL.legendChartStock,
      rangeSelector: {
        buttons: this.variablesGL.BotonesChartStock,
        selected: 0
      },
      yAxis: this.variablesGL.yAxisChartStockControl,
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        valueDecimals: 4,
        shared: true,
        crosshairs: true
      },
      "series": [
        {
          "name": "Caudal",
          "type": 'area',
          "color": "#40C4FF",
          "data": [],
          "yAxis": 0,
          "fillColor": {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, "#40C4FF"],
              [1, "rgba(124,181,236,0)"]
            ]
          }
        },
        {
          "name": "Presion ⇡",
          "color": "#76FF03",
          "data": [],
          "yAxis": 1
        },
        {
          "name": "Presion ↓",
          "color": "#FF6E40",
          "data": [],
          "yAxis": 1
        }
      ],
      exporting: {
        enabled: false
      }
    }
  }

  updateStockControl(lineCaudal, linepArriba, linepAbajo) {
    setTimeout(() => {
      this.stockControl.series[0].update({
        data: lineCaudal
      }, true);

      this.stockControl.series[1].update({
        data: linepArriba
      }, true);

      this.stockControl.series[2].update({
        data: linepAbajo
      }, true);
    }, 500);
  }
}
