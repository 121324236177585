import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Usuario } from '../../models/usuario.model';
import { VariablesService } from 'src/app/services/variableGL.service';

@Component({
  selector: 'app-side-user',
  templateUrl: './side-user.component.html',
  styleUrls: ['./side-user.component.css']
})
export class SideUserComponent implements OnInit {

  user: Usuario;
  visibleAlertas: boolean = false;

  constructor(
    private auth: AuthService,
    private variablesGL: VariablesService
  ) {
    this.auth.user.subscribe((data: Usuario) => {
      if (data)
        if (data.arbol != undefined) {
          this.user = data;
          this.visibleAlertas = data.rol == "Administrador" ? true : false;
        }
    });
  }

  ngOnInit() { }

  logout() {
    this.auth.logoff();
    this.variablesGL.existe.next(null);
  }
}
