import { Injectable } from '@angular/core';
import { Subject, Observable, Observer } from 'rxjs';
import { Notificacion } from 'src/app/shared/models/notificacion';



@Injectable({
  providedIn: 'root'
})
export class WsNtfrService {

  private url: string;
  constructor() { }

  private subject: Subject<MessageEvent>;

  public connect(url = this.url): Subject<MessageEvent> {
    this.url = url;
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  private create(url): Subject<MessageEvent> {
    let ws = new WebSocket(url);
    let observable = Observable.create(
      (obs: Observer<MessageEvent>) => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror = obs.error.bind(obs);
        return ws.close.bind(ws);
      });
    let observer = {
      next: (data: Notificacion) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    }
    ws.addEventListener('close', this.onClose)
    return Subject.create(observer, observable);
  }

  onClose() {
    console.log('desconectado');
    console.log("Reconectando Web Socket...");
    //location.reload();

  }
}