import { Component, Input, OnChanges } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';

declare const Highcharts;

@Component({
  selector: 'app-logger',
  templateUrl: './logger.component.html',
  styleUrls: ['./logger.component.css']
})
export class LoggerComponent implements OnChanges {

  @Input() dataLogger: any;
  stockLogger: any;

  constructor(
    private variablesGL: VariablesService
  ) { }

  ngOnChanges() {
    if (this.dataLogger) {

      let presion = this.dataLogger.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });

      this.stockLogger = Highcharts.stockChart('line-Area-Logger', this.setLogger())
      this.updateStockLogger(presion);
    }
  }

  setLogger() {
    return {
      chart: this.variablesGL.zoomChartStock,
      legend: this.variablesGL.legendChartStock,
      rangeSelector: {
        buttons: this.variablesGL.BotonesChartStock,
        selected: 0
      },
      yAxis: this.variablesGL.yAxisChartStockLogger,
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        valueDecimals: 4,
        split: false
      },
      "series": [
        {
          "name": "Presión",
          "type": 'area',
          "color": "#76FF03",
          "data": [],
          "yAxis": 0,
          "fillColor": {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1.5
            },
            stops: [
              [0, "#76FF03"],
              [1, "rgba(124,181,236,0)"]
            ]
          }
        }],
      exporting: {
        enabled: false
      }
    }

  }

  updateStockLogger(linePresion) {
    setTimeout(() => {
      this.stockLogger.series[0].update({
        data: linePresion
      }, true);
    }, 500);
  }

}
