export class Grouped {
    constructor(
        public seriesData: Serie[],
        public xaxisData: string[]
    ) {}
}

export class Serie {
    constructor(
        public name: string,
        public data: number[]
    ) { }
}