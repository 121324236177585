import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { VariablesService } from 'src/app/services/variableGL.service';
import * as XLSX from 'xlsx';
import { AlertaMasiva } from 'src/app/shared/models/alertaMasiva.model';
import { DispositivoService } from 'src/app/services/dispositivo.service';

@Component({
  selector: 'app-cargar',
  templateUrl: './cargar.component.html',
  styleUrls: ['./cargar.component.css'],
  providers: [MessageService]
})
export class CargarComponent implements OnInit {

  selectFile: any;
  nombre: string;
  size: string;
  barraProgress: number = 0;
  willDownload = false;

  correctos: number = 0;
  error: number = 0;
  table: any;

  constructor(
    private messageService: MessageService,
    private variablesGL: VariablesService,
    private dispositivoServicio: DispositivoService
  ) {

    this.dispositivoServicio.dataExcel.subscribe(d => {
      if (d) {
        let interval = setInterval(() => {
          this.barraProgress = this.barraProgress + Math.floor(Math.random() * 10) + 1;
          if (this.barraProgress >= 100) {
            this.barraProgress = 100;
            clearInterval(interval);

            this.correctos = d.correctos;
            this.error = d.error;
            if (d.listaError.length > 0) {
              this.table = this.variablesGL.getDataTable(d.listaError);

              let datafile = document.getElementById("dataFile");
              datafile.style.top = "1.5rem";
              let icon = document.getElementById("iconExcel");

              icon.style.top = "2%";
              icon.style.position = "absolute";
              icon.style.marginLeft = "-26rem";
            }
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: 'Alarmas Agregadas', life: 2000, closable: false });
          }
        }, 1000);

      }
    });
  }

  ngOnInit() {
    this.table = null;
    this.barraProgress = 0;
  }

  ngOnDestroy(): void {
    this.dispositivoServicio.dataExcel.observers = [];
  }

  selectedFile(files: any) {
    let _file: any = files.target.files.item(0);

    if (_file) {
      if (_file.size > 1000000)
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Tamaño mayor a 1 MB', life: 1000, closable: false });
      else {
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: 'Cargando datos de Excel', life: 1000, closable: false });
        let icon = document.getElementById("iconExcel");
        icon.setAttribute("class", "activeIcon");
        this.nombre = _file.name;
        this.size = this.variablesGL.getSizeFile(_file);

        setTimeout(() => {
          this.selectFile = files;
          this.convertExceltoJson(files);

        }, 1000);

      }
    }
  }

  convertExceltoJson(_file: any) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = _file.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.parseData(jsonData);
    }
    reader.readAsBinaryString(file);
  }

  parseData(data: any) {
    let alertas: AlertaMasiva[] = [];
    data.Hoja1.map((alerta) => {
      alertas.push(new AlertaMasiva(String(alerta.Dispositivo),
        String(alerta.Canal),
        parseFloat(alerta.Alto),
        parseFloat(alerta.Bajo),
      ));
    });

    this.dispositivoServicio.putAlarmaMasivas(JSON.stringify(alertas));

    this.messageService.add({ key: 'toast', severity: 'success', summary: 'Cargando', detail: 'Des encriptando datos', life: 2000, closable: false });

    let interval = setInterval(() => {
      this.barraProgress = this.barraProgress + 1.5;
      if (this.barraProgress >= 80) {
        this.barraProgress = 80;
        clearInterval(interval);
      }
    }, 2700);
  }
}
