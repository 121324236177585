import { Component, OnChanges, Input } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';

declare const Highcharts;
 var colors = Highcharts.getOptions().colors;


@Component({
  selector: 'app-multilog-dos',
  templateUrl: './multilog-dos.component.html',
  styleUrls: ['./multilog-dos.component.css']
})
export class MultilogDosComponent implements OnChanges {

  @Input() dataMultilogDos: any;
  stockMultilogDos: any;

  constructor(
    private variablesGL: VariablesService
  ) { }

  ngOnChanges() {
    if (this.dataMultilogDos) {

      let presion = this.dataMultilogDos.presion.registros.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });

      let caudal = this.dataMultilogDos.caudal.registros.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });
      
      this.stockMultilogDos = Highcharts.stockChart('line-Area-Dos', this.setMultilog())
      this.updateStockMultilog(caudal, presion);
    }
  }
  setMultilog() {
    console.log("colores ", colors);
    
    return {
      chart: this.variablesGL.zoomChartStock,
      legend: this.variablesGL.legendChartStock,
      rangeSelector: {
        buttons: this.variablesGL.BotonesChartStock,
        selected: 0
      },
      yAxis: this.variablesGL.yAxisChartStockMultilogDos,
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        valueDecimals: 4,
        shared: true,
        crosshairs: true
      },
      "series": [
        {
          "name": "Caudal",
          "type": 'area',
          "color": "#40C4FF",
          "data": [],
          "yAxis": 0,
          "fillColor": {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, "#40C4FF"],
              [1, "rgba(124,181,236,0)"]
            ]
          }
        },
        {
          "name": "Presion",
          "type": 'area',
          "color": "#76FF03",
          "data": [],
          "yAxis": 1,
          "fillColor": {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, "#76FF03"],
              [1, "rgba(124,181,236,0)"]
            ]
          }
        }],
        exporting: {
          enabled: false
        }
    }
  }

  updateStockMultilog(lineCaudal, linePresion) {
    setTimeout(() => {
      this.stockMultilogDos.series[0].update({
        data: lineCaudal
      }, true);
      this.stockMultilogDos.series[1].update({
        data: linePresion
      }, true);
    }, 500);
  }
}
