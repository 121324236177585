import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Proveedor } from '../../models/proveedor.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css'],
  providers: [MessageService]
})
export class ProveedoresComponent implements OnInit {

  @Input() data: any;
  proveedor: Proveedor;

  @Output() seleccionado = new EventEmitter<Proveedor>();

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit() {
    if (this.data) {
      this.proveedor = this.data;
    }
  }

  changeProveedor(e) {
    if (e.checked == false) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Acceso denegado', detail: 'Selecciona un Proveedor diferente', life: 1000, closable: false });
      setTimeout(() => {
        this.proveedor.estado = true;
      }, 500);
    }
    else {
      this.proveedor.estado = e.checked;
      this.seleccionado.emit(this.proveedor);
    }
  }
}

