import { environment } from "../../environments/environment";

export const URL_ServUser = environment.URL_Servicios + 'auth';
export const URL_ServAccount = environment.URL_Servicios + 'users';
export const URL_Servpanel = environment.URL_Servicios + 'dash';
export const URL_ServDevice = environment.URL_Servicios + 'device';
export const URL_ServLocation = environment.URL_Servicios + 'maps';
export const URL_ServReports = environment.URL_Servicios + 'reports';
export const URL_ServZonas = environment.URL_Servicios + 'zone';
export const URL_Servicios = environment.URL_Servicios;
export const URL_Mailing = environment.URL_Mailer;
// export const URL_Servicios = environment.URL_Servicios;
// export const URL_Servicios = environment.URL_Servicios;
// export const URL_Servicios = environment.URL_Servicios;
export const URL_Socket = environment.URL_Socket;
export const avatarUser = ["00", "01", "02", "03", "04", "05", "06", "07", "08"];
export const Colores =
  ['#ff5252',
    '#FF4081',
    '#E040FB',
    '#7C4DFF',
    '#536DFE',
    '#448AFF',
    '#40C4FF'];
//HIGHCHART
export const zoomChartStock = {
  zoomType: "xy"
};
export const legendChartStock = {
  itemStyle: {
    color: "#fff"
  },
  enabled: true,
  align: "right",
  backgroundColor: "#2e2b2b",
  borderColor: "#000000",
  borderWidth: 2,
  layout: "vertical",
  verticalAlign: "top",
  y: 100,
  shadow: true
};
export const BotonesChartStock = [
  {
    type: 'hour',
    count: 1,
    text: '1h'
  }, {
    type: 'hour',
    count: 2,
    text: '2h'
  }, {
    type: 'hour',
    count: 3,
    text: '3h'
  }, {
    type: "day",
    count: 1,
    text: "1d"
  }, {
    type: "day",
    count: 2,
    text: "2d"
  }, {
    type: "day",
    count: 3,
    text: "3d"
  }, {
    type: "week",
    count: 1,
    text: "1s"
  }, {
    type: "week",
    count: 2,
    text: "2s"
  }, {
    type: "month",
    count: 1,
    text: "1m"
  }, {
    type: "month",
    count: 2,
    text: "2m"
  }, {
    type: "all",
    text: "Todo"
  }
];
export const yAxisChartStockLogger = [
  {
    title: {
      text: "Presion",
      style: {
        color: "#000"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#000"
      }
    }
  }
];
export const yAxisChartStockControl = [
  {
    title: {
      text: "Caudal",
      style: {
        color: "#40C4FF"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#40C4FF"
      }
    },
    opposite: false
  },
  {
    title: {
      text: "Presion ⇡",
      style: {
        color: "#76FF03"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#76FF03"
      }
    }
  },
  {
    title: {
      text: "Presion ↓",
      style: {
        color: "#FF6E40"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#FF6E40"
      }
    }
  }
];
export const yAxisChartStockMultilogUno = [
  {
    title: {
      text: "Presion ⇡",
      style: {
        color: "#76FF03"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#76FF03"
      }
    },
    opposite: false
  },
  {
    title: {
      text: "Presion ↓",
      style: {
        color: "#FF6E40"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#FF6E40"
      }
    }
  }
];
export const yAxisChartStockMultilogDos = [
  {
    title: {
      text: "Caudal",
      style: {
        color: "#40C4FF"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#40C4FF"
      }
    },
    opposite: false
  },
  {
    title: {
      text: "Presion",
      style: {
        color: "#76FF03"
      }
    },
    labels: {
      format: "{value}",
      style: {
        color: "#76FF03"
      }
    }
  }
];
export const StyleMapaDia = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#ff4400"
      },
      {
        "saturation": -68
      },
      {
        "lightness": -4
      },
      {
        "gamma": 0.72
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon"
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#0077ff"
      },
      {
        "gamma": 3.1
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "hue": "#00ccff"
      },
      {
        "gamma": 0.44
      },
      {
        "saturation": -33
      }
    ]
  },
  {
    "featureType": "poi.park",
    "stylers": [
      {
        "hue": "#44ff00"
      },
      {
        "saturation": -23
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "hue": "#007fff"
      },
      {
        "gamma": 0.77
      },
      {
        "saturation": 65
      },
      {
        "lightness": 99
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "gamma": 0.11
      },
      {
        "weight": 5.6
      },
      {
        "saturation": 99
      },
      {
        "hue": "#0091ff"
      },
      {
        "lightness": -86
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "lightness": -48
      },
      {
        "hue": "#ff5e00"
      },
      {
        "gamma": 1.2
      },
      {
        "saturation": -23
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "saturation": -64
      },
      {
        "hue": "#ff9100"
      },
      {
        "lightness": 16
      },
      {
        "gamma": 0.47
      },
      {
        "weight": 2.7
      }
    ]
  }
];
export const StyleMapaNoche = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
];
