import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { URL_Servicios } from '../app/config/config';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  baseUrl: string;
  headers = new BehaviorSubject(null);

  constructor(
  ) {
    this.baseUrl = URL_Servicios;
  }

  setLocal() {
    let headers = { 'Content-Type': 'application/json' };
    let token = this.token;
    if (token)
      headers['Authorization'] = `Bearer ${token}`;
    this.headers.next(headers);
  }

  clearLocal() {
    localStorage.clear();
    this.headers.next(null);
  }

  get token() {
    return localStorage.d;
  }
}
