import { Component, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers: [MessageService]
})

export class UsuariosComponent implements OnInit {
  selectedUsers: Usuario[];
  selectedUser: Usuario;

  dataTable: any;

  constructor(
    private variablesGL: VariablesService,
    private userService: UsuarioService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.userService.getUsers();
    this.userService.users.subscribe(data => {
      if (data)
        this.dataTable = this.variablesGL.getDataTable(data);
    });
    this.userService.status.subscribe(s => {
      if (s) {
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: `Cambiando estatus de usuario`, life: 1000, closable: false });
      }
    });
  }

  onRowSelect(event) {
    this.selectedUser = event;
    this.userService.getUser(this.selectedUser.id);
  }

  changeStatus(status, data: Usuario) {
    Swal.fire({
        title: `¿Quieres ${status == true ? 'activar' : 'desactivar'} este Usuario?`,
        html: `Usuario: ${data.nombre} ${data.apellidos}`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `${status == true ? 'Activar' : 'Desactivar'}`,
        cancelButtonText: 'Cancelar'
    })
      .then((willOK) => {
        if (willOK) {
          let putUsuario = {
            usuario: data.id,
            habilitado: status
          };

          this.userService.putUserStatus(putUsuario);
        }
        else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Accion cancelada', detail: `No se cambio el estatus`, life: 1000, closable: false });
          let encontrado: Usuario = this.dataTable.rows.find(u => u.id == data.id);
          encontrado.habilitado = !status;
        }
      });
  }
}
