import { Component, OnInit, enableProdMode, NgZone } from '@angular/core';
import { MapaService } from 'src/app/services/mapa.service';
import { Dispositivo } from 'src/app/shared/models/dispositivo.model';
import { Proveedor } from 'src/app/shared/models/proveedor.model';
import { DispositivoService } from 'src/app/services/dispositivo.service';
import { Carta } from 'src/app/shared/models/card.model';
import { DashBoardService } from 'src/app/services/dashboard.service';
import { VariablesService } from 'src/app/services/variableGL.service';
import { MessageService } from 'primeng/api';

declare const google: any;

enableProdMode();

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
  gmap: any;

  proveedores: Proveedor[];
  correctos: Dispositivo[] = [];
  erroneos: Dispositivo[] = [];
  options: any;
  markers: any[] = [];
  dispositivoEliminar: [Dispositivo, boolean];

  modalDispositivo: boolean = false;
  _selectedDispositivo: Dispositivo;
  modalZonas: boolean = false;
  modalListDispositivos: boolean = false;

  selectedCard: [Carta, Proveedor];
  val1: string;

  constructor(
    private mapaService: MapaService,
    private dispositivoServicio: DispositivoService,
    private dashbaordService: DashBoardService,
    private _ngZone: NgZone,
    private variablesGL: VariablesService,
    private messageService: MessageService,
  ) {
    this.mapaService.listaDispositivos.subscribe(data => {
      this.clearOverlays();
      let infowindow = new google.maps.InfoWindow();
      if (data) {
        data.map((r: Dispositivo) => {
          if (r.latitud || r.latitud != 0)
            this.correctos.push(r);
          else
            this.erroneos.push(r);
        });

        this._ngZone.run(() => {

          this.correctos.map(r => {

            let marker = new google.maps.Marker({
              position: new google.maps.LatLng(r.latitud, r.longitud),
              map: this.gmap,
              animation: google.maps.Animation.DROP,
              icon: `assets/marker/${r.zona}.png`,
              title: r.nombre
            });

            this.markers.push(marker);

            google.maps.event.addListener(marker, 'mouseover', function () {
              infowindow.setContent('<strong style="font-weight:bold">Dispositivo</strong><hr>' + (<any> marker).title);
              infowindow.open(this.gmap, this);
            });

            google.maps.event.addListener(marker, 'mouseout', function () {
              infowindow.open(this.gmap);
            });

            google.maps.event.addListener(marker, 'click', () => {
              this.getDispositivo((<any> marker).title);
              infowindow.close();
              this.gmap.setCenter((<any> marker).position)
              this.gmap.setZoom(20)
            });
          });
        });

      }
    });
  }

  ngOnInit() {
    this.proveedores = [
      { titulo: "Todos", estado: true },
      { titulo: "Marfel", estado: false },
      { titulo: "Incotex", estado: false }
    ];

  }



  ngAfterContentInit(): void {
    const mapProp: any = {
      center: new google.maps.LatLng(19.058775, -98.205314),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: this.variablesGL.StyleMapaNoche
    };

    this.mapaService.getUbicaciones("", "");

    this.gmap = new google.maps.Map(document.getElementById("gmap"), mapProp);

    var setKML = new google.maps.KmlLayer({
      url: 'https://www.google.com/maps/d/kml?mid=1NqEqS1SoturYCfA6ftW_0JQm5rMrNpid',
      map: this.gmap,
      suppressInfoWindows: true,
      preserveViewport: true
    });

    setKML.setMap(this.gmap);
  }

  getSeleccionado(e: Proveedor) {
    if (e) {
      this.val1 = "";
      this.proveedores.map(p => {
        if (e.titulo == p.titulo)
          p.estado = e.estado;
        else
          p.estado = false;
      });
      this.mapaService.getUbicaciones((e.titulo == "Todos") ? '' : e.titulo, "");
    }
  }

  clearOverlays() {
    this.markers.map(r => {
      r.setMap(null)
    });

    this.correctos = [];
    this.erroneos = [];
    this.markers = [];
  }

  getDispositivo(nombre: string) {
    if (nombre != "") {
      this._selectedDispositivo = this.correctos.find(r => r.nombre == nombre);
      this.dispositivoServicio.getDispositivo(this._selectedDispositivo.id, '', '');
      this.dispositivoServicio.dispositivo.subscribe(data => {
        if (data)
          this.modalDispositivo = true;
      });
    }
  }

  getZona() {
    let proveedor = this.proveedores.find(p => p.estado == true)
    this.mapaService.getUbicaciones((proveedor.titulo == "Todos") ? '' : proveedor.titulo, this.val1);
  }

  openList(estado: boolean) {
    let proveedor = this.proveedores.find(p => p.estado == true)

    if (estado) {
      let carta: Carta = new Carta("fas fa-check", "Correctos", this.correctos.length, "#7bc67b");
      this.dashbaordService.listaDispositivos.next(this.correctos);
      this.selectedCard = [carta, proveedor];
    }
    else {
      let carta: Carta = new Carta("fas fa-times", "Erroneos", this.erroneos.length, "#f38181");
      this.dashbaordService.listaDispositivos.next(this.erroneos);
      this.selectedCard = [carta, proveedor];
    }
    this.modalListDispositivos = true;
  }

  selectedDispositivo($event: Dispositivo) {
    this._selectedDispositivo = $event;
    this.dispositivoServicio.getDispositivo(this._selectedDispositivo.id, '', '');
    this.dispositivoServicio.dispositivo.subscribe(data => {
      if (data)
        this.modalDispositivo = true;
    });
  }

  deleteDispositivo($event: Dispositivo) {
    let dataDispositivo = $event;

    if (dataDispositivo) {

      console.log("llego al dispositivo...");

      this.dispositivoServicio.putHabilitadoDispositivo(dataDispositivo, (allowed) => {
        if (!allowed) {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'error', detail: "Hubo un problema al Deshabilitar el Dispositivo", life: 2000, closable: false });
        } else {
          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: "Dispositivo Deshabilitado", life: 2000, closable: false });

          if (this.modalDispositivo) {
            this.modalDispositivo = false;
          }
          setTimeout(() => {
            this.mapaService.getUbicaciones("", "");
          }, 1000);

        }
      });

    }
  }
}
