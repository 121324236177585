import { Component, OnInit, OnDestroy } from '@angular/core';
import { Carta } from 'src/app/shared/models/card.model';
import { Envio } from 'src/app/shared/models/lastEnvio.model';
import { Proveedor } from 'src/app/shared/models/proveedor.model';
import { DashBoardService } from 'src/app/services/dashboard.service';
import { Barras } from 'src/app/shared/models/barras.model';
import { Gauge } from 'src/app/shared/models/gauge.model';
import { Dispositivo } from 'src/app/shared/models/dispositivo.model';
import { DispositivoService } from 'src/app/services/dispositivo.service';
import { MetodosSocket } from 'src/app/services/WebSocket/metodosSocket.service';
import { MessageService } from 'primeng/api';
import { VariablesService } from 'src/app/services/variableGL.service';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { AuthService } from 'src/app/services/auth.service';
import { Grouped } from 'src/app/shared/models/grouped.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  user: Usuario;
  mostrar: boolean;

  cards: Carta[];
  envios: Envio[];
  proveedores: Proveedor[];
  historicoBarras: Barras[];
  comunicanPorZona: Grouped;
  gauges: Gauge[];

  selectedCard: [Carta,Proveedor];
  modalListDispositivos : boolean = false;
  modalDispositivo: boolean = false;
  _selectedDispositivo: Dispositivo;
  estadoSonidoAlarmas: boolean = false;
  dispositivoEliminar: [Dispositivo, boolean];

  constructor(
    private dashbaordService:DashBoardService,
    private dispositivoServicio:DispositivoService,
    private metodosSocket: MetodosSocket,
    private variablesGL: VariablesService,
    private auth: AuthService
  ) {
    this.dashbaordService.dashboard.subscribe(data => {
      if (data) {

        this.cards = data.cartas;
        this.envios = data.envios;
        this.historicoBarras = data.barras;
        console.log(this.historicoBarras)
        this.comunicanPorZona = data.comunicanPorZona;
        this.gauges = data.gauges;
      }
    });

    this.metodosSocket.proveedor.subscribe(r => {
      if (r) {
        this.proveedores.map(p => {
          if (r == p.titulo)
            p.estado = true;
          else
            p.estado = false;
        });
      }
    });

    this.auth.user.subscribe((data: Usuario) => {
      if (data){
        if (data.arbol != undefined) {
          for (const item of JSON.parse(data.arbol)) {
            item.pantalla == "Deshabilitar Dispositivos" ? variablesGL.existe.next(true) : variablesGL.existe.next(false);
          }
          this.mostrar = variablesGL.existe.value;
          variablesGL.showSideUser.next(false);
        }
      }
    });
  }

  ngOnInit() {
    this.dashbaordService.getGraficas("");
    this.gauges = null;

    this.proveedores = [
      { titulo: "Todos", estado: true },
      { titulo: "Marfel", estado: false },
      { titulo: "Incotex", estado: false }
    ];
  }

  ngOnDestroy(){
    this.mostrar = null;
    this.variablesGL.existe.next(false);
  }

  getSeleccionado(e: Proveedor) {
    if (e) {
      this.proveedores.map(p => {
        if (e.titulo == p.titulo)
          p.estado = e.estado;
        else
          p.estado = false;
      });
      this.dashbaordService.getGraficas((e.titulo == "Todos") ? '' : e.titulo);
    }
  }

  selectedDispositivo($event : Dispositivo) {
    this._selectedDispositivo = $event;
    this.dispositivoServicio.getDispositivo(this._selectedDispositivo.id, '', '');
    this.dispositivoServicio.dispositivo.subscribe(data => {
      if (data)
        this.modalDispositivo = true;
    });
  }

  updateCards($event){
    if($event){
      this.dashbaordService.getGraficas("");
    }
  }

  loggerEliminar($event : Dispositivo){
    let dataDispositivo = $event;
    this.dispositivoServicio.putHabilitadoDispositivo(dataDispositivo, (allowed) => {
        if (!allowed)
          this.dispositivoEliminar = [dataDispositivo, false];
        else{
          this.dispositivoEliminar = [dataDispositivo, true];
        }
    });

    if(this.modalDispositivo){
      this.modalDispositivo = false;
    }
  }

  selectCard(carta : Carta) {
    let proveedor = this.proveedores.find(p => p.estado == true)
    this.selectedCard = [carta,proveedor];
    this.dashbaordService.getCard(proveedor.titulo == 'Todos' ? '' : proveedor.titulo,carta.titulo);
    this.modalListDispositivos = true;
  }


}
