import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from './passForm.validator';
import { Password } from 'src/app/shared/models/password.model';

@Component({
  selector: 'app-fpassword',
  templateUrl: './fpassword.component.html',
  styleUrls: ['./fpassword.component.css']
})
export class FpasswordComponent implements OnInit {

  @ViewChild('myForm', { static: false, }) formValues;
  form: FormGroup;
  submitted = false;

  spiner: boolean = false;

  @Output() dataUser = new EventEmitter<Password>();

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      passOld: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(9)]],
      confirmPassword: ['', Validators.required]
    }, { validator: MustMatch('password', 'confirmPassword') });
  }

  get f() { return JSON.stringify(this.form.controls); }

  onSubmit() {
    this.submitted = true;
    this.spiner = true;

    if (this.form.invalid) {
      this.spiner = false
      return;
    }
    else {
      this.dataUser.emit(new Password(this.form.value.passOld, this.form.value.password));
      this.submitted = false;

      setTimeout(() => {
        this.spiner = false;
        this.formValues.resetForm();
      }, 1500);
    }
  }
}
