import { Injectable } from '@angular/core';
import { HttpClientService } from './implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { VariablesService } from './variableGL.service';
import { Dashboard } from '../shared/models/dashboard.model';
import { Dispositivo } from '../shared/models/dispositivo.model';
import { Alerta } from '../shared/models/alerta.model';
import { Proveedor } from '../shared/models/proveedor.model';
import { URL_Servpanel } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class DashBoardService {

    dashboard = new BehaviorSubject<Dashboard>(null);
    listaDispositivos = new BehaviorSubject<Dispositivo[]>(null);
    alertas = new BehaviorSubject<Alerta[]>(null);
    proveedor = new BehaviorSubject<string>(null);

    constructor(
        private http: HttpClientService,
        private variablesGL: VariablesService,
        private router: Router
    ) { }

    getGraficas(proveedor: string) {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_Servpanel}/dash?proveedor=${proveedor}`)
            .subscribe((res: any) => {
                if (res) {
                    this.dashboard.next(res);
                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    getCard(proveedor: string, carta: string) {
        this.variablesGL.showLoading.next(true);

        this.http.get(`${URL_Servpanel}/card?proveedor=${proveedor}&&carta=${carta}`)
            .subscribe((res: any) => {
                if (res) {
                    if (res.mensaje == 'Exito')
                        this.listaDispositivos.next(res.lista);
                    else
                        this.listaDispositivos.next([]);

                    this.variablesGL.showLoading.next(false);
                }
            }, (err) => {
                console.error(err);
                if (err.status == 401) {
                    this.router.navigate(['/'], { replaceUrl: true });

                    location.reload();
                }
            });
    }

    getAlertas() {
        this.http.get(`${URL_Servpanel}/alerts`)
            .subscribe((res: any) => {
                if (res) {
                    this.alertas.next(res);
                }
            }, (err) => {
                console.error(err);
            });
    }

}
