export class Dispositivo {
    constructor(
        public id: number,
        public proveedor: string,
        public zona: string,
        public nombre: string,
        public serial: string,
        public latitud: number,
        public longitud: number,
        public telefono: number,
        public bateriaActual: number,
        public bateriaTotal: number,
        public registro: number,
        public habilitado: boolean
    ){}
}