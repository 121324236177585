import { Component, Input, OnChanges } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';

declare const Highcharts;
var col = Highcharts.getOptions().colors[0];

@Component({
  selector: 'app-multilog-uno',
  templateUrl: './multilog-uno.component.html',
  styleUrls: ['./multilog-uno.component.css']
})
export class MultilogUnoComponent implements OnChanges {

  @Input() dataMultilogUno: any;
  stockMultilogUno: any;

  constructor(
    private variablesGL: VariablesService
  ) { }

  ngOnChanges() {
    if (this.dataMultilogUno) {

      let abajo = this.dataMultilogUno.abajo.registros.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });

      let arriba = this.dataMultilogUno.arriba.registros.map((r: any) => {
        return [new Date(`${r.fecha}Z`).getTime(), r.valor];
      });
      
      this.stockMultilogUno = Highcharts.stockChart('line-Area-Uno', this.setMultilog())
      this.updateStockMultilog(arriba, abajo);

    }
  }

  setMultilog() {
    return {
      chart: this.variablesGL.zoomChartStock,
      legend: this.variablesGL.legendChartStock,
      rangeSelector: {
        buttons: this.variablesGL.BotonesChartStock,
        selected: 0
      },
      yAxis: this.variablesGL.yAxisChartStockMultilogUno,
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        valueDecimals: 4,
        shared: true,
        crosshairs: true
      },
      "series": [
        {
          "name": "Presion ⇡",
          "type": 'area',
          "color": "#76FF03",
          "data": [],
          "yAxis": 0,
          "fillColor": {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1.5
            },
            stops: [
              [0, "#76FF03"],
              [1, "rgba(124,181,236,0)"]
            ]
          }
        },
        {
          "name": "Presion ↓",
          "type": 'area',
          "color": "#FF6E40",
          "data": [],
          "yAxis": 0,
          "fillColor": {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, "#FF6E40"],
              [1, "rgba(124,181,236,0)"]
            ]
          }
        }],
        exporting: {
          enabled: false
        }
    }
  }

  updateStockMultilog(lineCaudal, linePresion) {
    setTimeout(() => {
      this.stockMultilogUno.series[0].update({
        data: lineCaudal
      }, true);
      this.stockMultilogUno.series[1].update({
        data: linePresion
      }, true);
    }, 500);
  }
}
