import { Component, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/services/variableGL.service';
import { MessageService } from 'primeng/api';
import { ReporteService } from 'src/app/services/reportes.service';
import { DispositivoService } from 'src/app/services/dispositivo.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rdispositivos',
  templateUrl: './rdispositivos.component.html',
  styleUrls: ['./rdispositivos.component.css'],
  providers: [MessageService]
})
export class RdispositivosComponent implements OnInit {
  selectedDispositivo: any;

  dataTable: any;

  modalDispositivo: boolean = false;

  constructor(
    private variablesGL: VariablesService,
    private messageService: MessageService,
    private reporteService: ReporteService,
    private dispositivoServicio: DispositivoService,
    public datepipe: DatePipe
  ) {
    this.reporteService.dispositvos.subscribe(data => {
      if (data) {
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Completado', detail: `Reporte de Dispositivos`, life: 1000, closable: false });
        this.dataTable = this.variablesGL.getDataTable(data);
      }
    });
  }

  ngOnInit() {
    if (this.reporteService.dispositvos.value == null)
      this.reporteService.getDispositivos();
  }

  onRowSelect(event) {
    this.selectedDispositivo = event;
    this.dispositivoServicio.getDispositivo(this.selectedDispositivo.id, '', '');
    this.dispositivoServicio.dispositivo.subscribe(data => {
      if (data)
        this.modalDispositivo = true;
    });
  }

  findDate() {
    let _fechaInicial: any = document.getElementById("date-inicial");
    if (_fechaInicial.value != "") {
      this.messageService.add({ key: 'toast', severity: 'success', summary: 'Buscando . . .', detail: `Espera unos segundos`, life: 1000, closable: false });
      let _fecha = String(this.datepipe.transform(_fechaInicial.value, 'shortDate'));
      this.reporteService.getDispositivosDate(_fecha);
    }
    else {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: `Selecciona una fecha`, life: 1000, closable: false });
    }
  }
}
