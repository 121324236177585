import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { PagesComponent } from 'src/app/pages/pages.component';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { NotFoundComponent } from 'src/app/pages/not-found/not-found.component';
import { PerfilComponent } from 'src/app/pages/perfil/perfil.component';
import { RolesComponent } from 'src/app/pages/roles/roles.component';
import { UsuariosComponent } from 'src/app/pages/usuarios/usuarios.component';
import { UsuarioComponent } from 'src/app/pages/usuario/usuario.component';
import { AddUserComponent } from 'src/app/pages/add-user/add-user.component';
import { ActivasComponent } from 'src/app/pages/notificaciones/activas/activas.component';
import { HistoicoComponent } from 'src/app/pages/notificaciones/histoico/histoico.component';
import { MapaComponent } from 'src/app/pages/mapa/mapa.component';
import { RdispositivosComponent } from 'src/app/pages/reporte/rdispositivos/rdispositivos.component';
import { ZonasComponent } from 'src/app/pages/zonas/zonas.component';
import { CargarComponent } from 'src/app/pages/notificaciones/cargar/cargar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([
      {
        path: '',
        component: PagesComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: DashboardComponent, data: { titulo: 'Panel de Control' } },
          { path: 'perfil', component: PerfilComponent, data: { titulo: 'Perfil' } },
          { path: 'roles', component: RolesComponent, data: { titulo: 'Roles' } },
          { path: 'usuarios', component: UsuariosComponent, data: { titulo: 'Usuarios' } },
          { path: 'usuario/:seleccionado', component: UsuarioComponent, data: { titulo: 'Usuario' } },
          { path: 'add-User', component: AddUserComponent, data: { titulo: 'Crear Usuario' } },
          { path: 'notifi-activas', component: ActivasComponent, data: { titulo: 'Alertas Activas' } },
          { path: 'notifi-historico', component: HistoicoComponent, data: { titulo: 'Histórico de Alertas' } },
          { path: 'cargar', component: CargarComponent, data: { titulo: 'Alta Masiva de Alertas' } },
          { path: 'mapa', component: MapaComponent, data: { titulo: 'Mapa' } },
          { path: 'reporte/dispositivos', component: RdispositivosComponent, data: { titulo: 'Reporte / Dispositivos' } },
          { path: 'zonas', component: ZonasComponent, data: { titulo: 'Zonas' } },
        ]
      }, {
        path: 'login',
        component: LoginComponent
      }, {
        path: 'not-found',
        component: NotFoundComponent
      }, {
        path: '**',
        redirectTo: '/login',
        pathMatch: 'full'
      }
    ])
  ],
  exports: [RouterModule]
})
export class RoutesModule { }

export const RouterComponents = [
  NotFoundComponent,
  LoginComponent,
  PagesComponent,
  DashboardComponent
];
