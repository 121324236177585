import { Component, Input, OnChanges } from '@angular/core';
import { Barras } from 'src/app/shared/models/barras.model';

import * as ApexCharts from 'apexcharts';

@Component({
  selector: 'app-barras',
  templateUrl: './barras.component.html',
  styleUrls: ['./barras.component.css']
})
export class BarrasComponent implements OnChanges {

  @Input() barras: Barras[];
  chartBarras: ApexCharts;

  colores = ['#ff5252', '#E040FB', '#536DFE', '#40C4FF', '#64FFDA', '#B2FF59', '#FFD740', '#FF6E40'];

  constructor() { }

  ngOnChanges() {
    if (this.barras) {
      console.log(this.barras)
      if (this.chartBarras) {
        this.chartBarras.updateOptions(this.getOptions(),true,true)
      }
      else {
        this.chartBarras = new ApexCharts(document.querySelector("#chartBarras"), this.getOptions());
        this.chartBarras.render();
      }
    }
  }

  getOptions(): any {
    return {
      title: {
        text: 'Histórico de comunicación',
        floating: true,
        align: 'center',
        style: {
          color: '#444'
        }
      },
      chart: {
        type: 'bar'
      },
      colors: this.colores,
      plotOptions: {
        bar: {
          columnWidth: '80%',
          distributed: true,
          horizontal: true,
        }
      },
      series: [{
        name: 'Dispositivos que Comunican',
        data: this.barras.map(r => {
          console.log(r)
            return r.total
        })
      }],
      dataLabels: {
        colors: ["#fdf6f6"]
      },
      xaxis: {
        categories: this.barras.map(r => {
            return String(r.fecha).slice(0, 10)
        })
      }
    }
  }
}


